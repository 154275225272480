import React from 'react';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 min-w-full">
    <div className="flex flex-col items-center justify-center h-fit p-10 rounded-lg bg-gray-100 text-gray-800">
      <h1 className="text-9xl font-bold mb-4">404</h1>
      <h2 className="text-3xl font-semibold mb-4">הדף לא נמצא</h2>
      <p className="text-lg mb-8">מצטערים, הדף שאתה מחפש לא קיים.</p>
      <Link to="/" className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700">
        חזור לדף הראשי
      </Link>
    </div>
    </div>
  );
};

export default NotFound;
