import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BaseUrl from 'src/config';
function Guess() {
  const [userId, setUserId] = useState('');
  const [message, setMessage] = useState('');
  const [takenNumbers, setTakenNumbers] = useState<number[]>([]);
  const [minigameBank, setMinigameBank] = useState<number>(0);
  const [nickname, setNickname] = useState<string>('');
  const [guess, setGuess] = useState('');
  const [isLuckyNumberGuessed, setIsLuckyNumberGuessed] = useState(false);

const [isGameOver, setIsGameOver] = useState(false);
  const fetchGameData = async () => {
  try {
    const token = localStorage.getItem('token');
    const gameDataResponse = await axios.get(`${BaseUrl}/taken-numbers`, {
      headers: {
        Authorization: token,
      },
    });

    const { minigameBank, takenNumbers, userInfo, isLuckyNumberGuessed } = gameDataResponse.data;
    const isLucky = gameDataResponse.data.isLuckyNumberGuessed;

    setMinigameBank(minigameBank);
    setTakenNumbers(takenNumbers);
    setNickname(userInfo.nickname);
    setUserId(userInfo.id);
    setIsGameOver(takenNumbers.length === 100);
    setIsLuckyNumberGuessed(isLucky);
} catch (error) {
    console.error('Error fetching game data:', error);
}
};
console.log(userId)
  useEffect(() => {
    fetchGameData();
  }, []);

  const handleGuess = async (selectedNumber: number) => {
    try {
      if (isGameOver) {
        setMessage('מישהו כבר זכה');
        return;
      }

      const response = await axios.post(`${BaseUrl}/guess`, { userId, guess: selectedNumber });
      setMessage(response.data.message);
      setUserId('');
      setGuess('');
      setTakenNumbers([]);
      setMinigameBank(0);
      setNickname('');
      fetchGameData();
    } catch (error) {
      console.error('Error:', error);
    }
  };

return (
  <div className="w-widthcon mx-auto items-center justify-center text-center mt-5">
    <h1 className="text-3xl font-bold mb-4">נחש את המספר וזכה בקופה!</h1>
    <div className="mb-4 text-lg">
      <p>שלום, <span className='text-red-500 font-semibold'>{nickname}</span>
      <br />
      כשאתה נכנס למשחק ניחושים, אתה מקבל רשימת מספרים מ-1 עד 100 שאתה יכול לבחור מתוכם. <br /> אתה מנסה לנחש את המספר המזלי שנבחר מראש על ידי המערכת. <br /> אתה בוחר מספר ולוחץ עליו, והמערכת בודקת האם הניחוש שלך נכון. <br /> אם הניחוש נכון, אתה זוכה בכל הזכייה בקופה! אם הניחוש אינו נכון, תוכל לנסות שוב בפעם הבאה.
      </p>
      
      <div className='flex flex-col justify-center w-full items-center mt-5'>
        <p className='p-3 bg-gold text-gold bg-opacity-10 backdrop-blur-lg text-2xl w-fit rounded mb-5 border border-white border-opacity-15'>זהב בקופה: {minigameBank}</p>
        <p className='p-3 bg-iron text-iron bg-opacity-10 backdrop-blur-lg text-2xl w-fit rounded border border-white border-opacity-15'>עלות ניחוש: {Math.ceil(minigameBank * 0.05)}</p>
        
{isLuckyNumberGuessed === true ? (
  <div className='bg-wood text-wood w-fit rounded p-3 text-3xl border border-white border-opacity-20 bg-opacity-10 backdrop-blur-lg mt-5'>
    <p>מישהו כבר זכה! נסה שוב בפעם הבאה.</p>
  </div>
) : null}
{message && (

    <p className='mt-5 rounded p-4 w-fit text-xl bg-food text-food bg-opacity-10 backdrop-blur-lg border border-1 border-white border-opacity-10'>{message}</p>
)}
      </div>
    </div>
<div className='flex flex-col items-center'>
    <div className="grid grid-cols-10 gap-2 mb-2 w-2/3 items-center">
      {Array.from({ length: 100 }, (_, index) => index + 1).map(number => (
        <button
          key={number}
          className={`bg-white bg-opacity-20 backdrop-blur-3xl hover:bg-green-700 hover:bg-opacity-50 disabled:bg-red-500 disabled:bg-opacity-30 border border-white border-opacity-20  text-white font-bold py-3 px-4 rounded m-1 ${takenNumbers.includes(number) || isLuckyNumberGuessed ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
          onClick={() => handleGuess(number)}
          disabled={takenNumbers.includes(number) || isLuckyNumberGuessed}
        >
          {number}
        </button>
      ))}
    </div></div>



    <div className='text-red-500'>


    </div>


  </div>
);
}

export default Guess;
