import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {jwtDecode} from "jwt-decode";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import BaseUrl from 'src/config';
import { getTimeDifference } from './getTimeDiffrence';

interface Message {
  senderId: string;
  senderNickname: string;
  message_content: string;
  message_title: string;
  message_id: number;
  timestamp: string;
  is_read: boolean;
}

const Inbox = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [messagesPerPage] = useState(10);
  const [unreadCount, setUnreadCount] = useState<number>(0);

  useEffect(() => {
    fetchMessages();
    fetchUnreadCount();
  }, [currentPage]);

  const fetchMessages = async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const userId: any = jwtDecode(token);

        const response = await axios.get<Message[]>(`${BaseUrl}/messages/${userId.email}/inbox`, {
          headers: {
            Authorization: token,
          },
        });

        // Sort messages by timestamp in descending order
        const sortedMessages = response.data.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
        setMessages(sortedMessages);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const fetchUnreadCount = async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const response = await axios.get(`${BaseUrl}/messages/unread-count`, {
          headers: {
            Authorization: token,
          },
        });
        setUnreadCount(response.data.unreadCount);
      }
    } catch (error) {
      console.error('Error fetching unread messages count:', error);
    }
  };

  const markAsRead = async (messageId: number) => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        await axios.put(`${BaseUrl}/messages/${messageId}/mark-as-read`, null, {
          headers: {
            Authorization: token,
          },
        });
        fetchMessages(); // Re-fetch messages to update the UI
      }
    } catch (error) {
      console.error('Error marking message as read:', error);
    }
  };

  const indexOfLastMessage = currentPage * messagesPerPage;
  const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
  const currentMessages = messages.slice(indexOfFirstMessage, indexOfLastMessage);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div className='w-widthcon'>
      <div className="letters bg-black bg-opacity-30 backdrop-blur-lg rounded-lg border border-white border-opacity-10 mt-5 mb-5 flex justify-around text-center">
        <Link
          className="p-3 m-2 w-1/4 text-2xl bg-black bg-opacity-20 transition-all duration-100 rounded-md border border-white border-opacity-5"
          to="/letters/outbox"
        >
          דואר יוצא
        </Link>
        <Link
          className="p-3 m-2 w-1/4 text-2xl bg-black bg-opacity-40 transition-all duration-100 rounded-md border border-white border-opacity-5"
          to="/letters/inbox"
        >
          דואר נכנס
        </Link>
        <Link
          className="p-3 m-2 w-1/4 text-2xl bg-black bg-opacity-20 transition-all duration-100 rounded-md border border-white border-opacity-5"
          to="/letters/new_letter"
        >
          שלח מכתב חדש
        </Link>
      </div>

      <table className='w-full'>
        <thead className='desert'>
          <tr>
            <th className="p-3">סטטוס</th>
            <th className="p-3">כותרת</th>
            <th className="p-3">שולח</th>
            <th className="p-3">זמן</th>
          </tr>
        </thead>
        <tbody className='w-full text-center text-black'>
          {currentMessages.map((message, index) => (
            <tr key={index} className={index % 2 === 0 ? 'hover:bg-white hover:bg-opacity-10 transition-all duration-100 text-white font-semibold bg-black bg-opacity-20 backdrop-blur-lg' : 'hover:bg-white hover:bg-opacity-10 transition-all duration-100 text-white font-semibold bg-black bg-opacity-40 backdrop-blur-lg'}>
              <td className="p-3">
                {message.is_read ? (
                  <i
                    data-tooltip-id="readed"
                    className="fa-regular fa-comment text-white text-opacity-50"
                  ></i>
                ) : (
                  <i
                    data-tooltip-id="unreaded"
                    className="fa-solid fa-comment text-white"
                  ></i>
                )}
              </td>
              <td className='p-3'>
                <Link 
                  to={`/letters/${message.message_id}`} 
                  className="hover:underline"
                  onClick={() => markAsRead(message.message_id)}
                >
                  {message.message_title}
                </Link>
              </td>
              <td className='p-3'>
                <Link className='hover:underline' to={`/profile/${message.senderId}`}>
                  {message.senderNickname}
                </Link>
              </td>
              <td className='p-3'>{getTimeDifference(message.timestamp)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      
      <div className="flex justify-center mt-4 mb-4">
        {Array.from(Array(Math.ceil(messages.length / messagesPerPage)).keys()).map(page => (
          <button 
            key={page} 
            onClick={() => paginate(page + 1)} 
            className={`mx-1 px-3 py-1 rounded-md ${currentPage === page + 1 ? 'bg-white bg-opacity-20 backdrop-blur-md text-white' : 'bg-gray-300 text-gray-700 hover:bg-gray-400'}`}
          >
            {page + 1}
          </button>
        ))}
      </div>
      
      <ReactTooltip
        style={{ backgroundColor: 'rgb(55, 55, 55)', color: '#fff' }}
        id="readed"
        place="bottom"
        variant="info"
        content="המכתב נקרא"
      />
      <ReactTooltip
        style={{ backgroundColor: 'rgb(55, 55, 55)', color: '#fff' }}
        id="unreaded"
        place="bottom"
        variant="info"
        content="המכתב לא נקרא"
      />
    </div>
  );
};

export default Inbox;
