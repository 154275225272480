import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import BaseUrl from 'src/config';
import { getTimeDifference } from './getTimeDiffrence';
interface Message {
  receiver_id: string;
  receiverNickname: string;
  message_title: string;
  message_id: number;
  timestamp: string;
  email: string;
}

const Outbox = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [messagesPerPage] = useState(10);

  useEffect(() => {
    fetchMessages();
  }, [currentPage]);

  const fetchMessages = async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const userId: any = jwtDecode(token);

        const response = await axios.get<Message[]>(`${BaseUrl}/messages/${userId.email}/outbox`, {
          headers: {
            Authorization: token,
          },
        });

        // Sort messages by timestamp in descending order
        const sortedMessages = response.data.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
        setMessages(sortedMessages);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const indexOfLastMessage = currentPage * messagesPerPage;
  const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
  const currentMessages = messages.slice(indexOfFirstMessage, indexOfLastMessage);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div className='w-widthcon'>
      <div className="letters bg-black bg-opacity-30 backdrop-blur-lg rounded-lg border border-white border-opacity-10 mt-5 mb-5 flex justify-around text-center">
    <Link
        className="p-3 m-2 w-1/4 text-2xl bg-black bg-opacity-40 transition-all duration-100 rounded-md border border-white border-opacity-5"
        to="/letters/outbox"
    >
        דואר יוצא
    </Link>
    <Link
        className="p-3 m-2 w-1/4 text-2xl bg-black bg-opacity-20 transition-all duration-100 rounded-md border border-white border-opacity-5"
        to="/letters/inbox"
    >
        דואר נכנס
    </Link>
    <Link
        className="p-3 m-2 w-1/4 text-2xl bg-black bg-opacity-20 transition-all duration-100 rounded-md border border-white border-opacity-5"
        to="/letters/new_letter"
    >
        שלח מכתב חדש
    </Link>
</div>

      <table className='w-full'>
        <thead className='desert'>
          <tr>
            <th className='p-3'>כותרת</th>
            <th className='p-3'>נמען</th>
            <th className='p-3'>זמן</th>
          </tr>
        </thead>
        <tbody className='w-full text-center text-black'>
          {currentMessages.map((message, index) => (
            <tr key={index} className={index % 2 === 0 ? 'hover:bg-white hover:bg-opacity-10 transition-all duration-100 text-white font-semibold bg-black bg-opacity-20 backdrop-blur-lg ' : 'hover:bg-white hover:bg-opacity-10 transition-all duration-100 text-white font-semibold bg-black bg-opacity-40 backdrop-blur-lg '}>
              <td className='p-3'>
  <Link to={`/letters/${message.message_id}`} className="hover:underline">
    {message.message_title}
  </Link>
</td>
              <td className='p-3'><Link className='hover:underline' to={`/profile/${message.receiver_id}`}>{message.receiverNickname}</Link></td>
              <td className='p-3'>{getTimeDifference(message.timestamp)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-center mt-4 mb-4">
        {Array.from(Array(Math.ceil(messages.length / messagesPerPage)).keys()).map(page => (
          <button key={page} onClick={() => paginate(page + 1)} className={`mx-1 px-3 py-1 rounded-md ${currentPage === page + 1 ? 'mx-1 px-3 py-1 rounded-md bg-white bg-opacity-20 backdrop-blur-md text-white' : 'bg-gray-300 text-gray-700 hover:bg-gray-400'}`}>{page + 1}</button>
        ))}
      </div>
    </div>
  );
};

export default Outbox;
