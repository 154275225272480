export const getTimeDifference = (timestamp: string): string => {
  const messageTime = new Date(timestamp).getTime();
  const currentTime = new Date().getTime();
  const difference = currentTime - messageTime;
  
  const second = 1000;
  const minute = 60 * 1000;
  const hour = minute * 60;
  const day = hour * 24;
  const month = day * 30;
  const year = day * 365;
  
  if (difference < minute) {
    const seconds = Math.floor(difference / second);
    return `לפני ${seconds} שניות`;
  } else if (difference < 2 * minute) {
    return `לפני דקה`;
  } else if (difference < hour) {
    const minutes = Math.floor(difference / minute);
    return `לפני ${minutes} דקות`;
  } else if (difference < 2 * hour) {
    return `לפני שעה`;
  } else if (difference < 3 * hour) {
    return `לפני שעתיים`;
  } else if (difference < day) {
    const hours = Math.floor(difference / hour);
    return `לפני ${hours} שעות`;
  } else if (difference < 2 * day) {
    return `אתמול`;
  } else if (difference < 3 * day) {
    return `לפני יומיים`;
  } else if (difference < month) {
    const days = Math.floor(difference / day);
    return `לפני ${days} ימים`;
  } else if (difference < 2 * month) {
    return `לפני חודש`;
  } else if (difference < 3 * month) {
    return `לפני חודשיים`;
  } else if (difference < year) {
    const months = Math.floor(difference / month);
    return `לפני ${months} חודשים`;
  } else if (difference < 2 * year) {
    return `לפני שנה`;
  } else if (difference < 3 * year) {
    return `לפני שנתיים`;
  } else {
    const years = Math.floor(difference / year);
    return `לפני ${years} שנים`;
  }
};
