import { PieChart } from '@mui/x-charts/PieChart'
import React from 'react'

const Work = () => {
  return (
    <div className='w-widthcon flex gap-5 flex-row mt-5'>
    <div className='w-full flex gap-5'>
    <div className='w-1/2 bg-black backdrop-blur-lg bg-opacity-20 mb-5 p-5 rounded-lg border border-white border-opacity-10'>

							<i className="fa fa-th-list text-xl ml-1"></i>
							<span className="text-xl">עבדים פנויים ()</span>
                            <div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                            <div className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col mb-2 text-gold'>
                                <span>עובדי זהב</span>
                                זהב עבור כל עדכון: 0
              <input
              type="number"
              className="bg-black bg-opacity-20 p-2 border border-dashed text-white text-center placeholder-white border-white border-opacity-20 mt-3 rounded-sm"
              />
                            </div>
                            <div className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col mb-2 text-iron'>
                                <span>עובדי ברזל</span>
                                ברזל עבור כל עדכון: 0
                                              <input
              type="number"
              className="bg-black bg-opacity-20 p-2 border border-dashed text-white text-center placeholder-white border-white border-opacity-20 mt-3 rounded-sm"
              />
                            </div>
                            <div className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col mb-2 text-wood'>
                                <span>עובדי עץ</span>
                                עץ עבור כל עדכון: 0
                                              <input
              type="number"
              className="bg-black bg-opacity-20 p-2 border border-dashed text-white text-center placeholder-white border-white border-opacity-20 mt-3 rounded-sm"
              />
                            </div>
                            <div className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col mb-2 text-food'>
                                <span>עובדי אוכל</span>
                                אוכל עבור כל עדכון: 0
                                              <input
              type="number"
              className="bg-black bg-opacity-20 p-2 border border-dashed text-white text-center placeholder-white border-white border-opacity-20 mt-3 rounded-sm"
              />
                            </div>
						          <button type="submit" className="p-2 border mt-2 border-white border-opacity-20 backdrop-xl bg-white bg-opacity-10 hover:bg-opacity-20 transition-all duration-150 focus:scale-95 rounded-lg w-full">
            הכנס
          </button>
                    <button type="submit" className="p-2 border mt-2 border-white border-opacity-20 backdrop-xl bg-white bg-opacity-10 hover:bg-opacity-20 transition-all duration-150 focus:scale-95 rounded-lg w-full">
            הוצא
          </button>
</div>
         <div className='w-1/2 bg-black backdrop-blur-lg bg-opacity-20 mb-5 p-5 rounded-lg border border-white border-opacity-10'>

							<i className="fa fa-university text-xl ml-1"></i>
							<span className="text-xl">עובדים פעילים</span>
                            <div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                            <div className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col mb-2'>
                                0
                                <span>זהב</span>
                            </div>
                            <div className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col mb-2'>
                                0
                                <span>ברזל</span>
                            </div>
                            <div className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col mb-2'>
                                0
                                <span>עץ</span>
                            </div>
                            <div className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col mb-2'>
                                0
                                <span>אוכל</span>
                            </div>
						
<div className='flex justify-center items-center w-150 h-110'>
        <PieChart

  series={
    [
    
    {
      faded: { innerRadius: 10, additionalRadius: -10, color: 'gray' },
                  highlightScope: { faded: 'global', highlighted: 'item' },
     data: 
      [
        { id: 0, value: 1, color: '#f39c12', label: 'זהב' },
        { id: 1, value: 1, color: '#00bc8c', label: 'ברזל' },
        { id: 2, value: 1, color: '#e63131', label: 'עץ' },
        { id: 3, value: 1, color: '#31dce6', label: 'אוכל' },
        
      ],
      innerRadius: 0,
      outerRadius: 80,
      paddingAngle: 0,
      cornerRadius: 0,
    }
    
  ]
  }
        margin={{ right: 5 }}
        width={200}
        height={200}
        slotProps={{
          legend: { hidden: true },
        }}

/></div>
</div>
        </div>
</div>
  )
}

export default Work