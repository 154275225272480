// Profile.tsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import "./Profile.scss"
import BaseUrl from 'src/config';
interface Player {
  id: string;
  nickname: string;
  city: string;
  gold: number;
  rank_city: number;
  rank_total: number;
  isAdmin: boolean;
  clan: string;
}

const Profile: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [player, setPlayer] = useState<Player | null>(null);

  useEffect(() => {
  const fetchPlayer = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get<Player>(`${BaseUrl}/profile/${id}`, {
                  headers: {
            Authorization: token,
          },
      });
      
      setPlayer(response.data);
    } catch (error) {
      console.error('Error fetching player information:', error);
    }
  };

  fetchPlayer();
}, [id]);

  if (!player) {
    return <div></div>;
  }

  return (
    <div className='w-widthcon flex flex-wrap mt-5 justify-evenly rounded-lg bg-black bg-opacity-20 backdrop-blur-sm border border-1 border-opacity-10 border-white'>
        
      <h2 className='w-full p-5 text-bold text-2xl bg-black bg-opacity-10 border-white backdrop-blur-sm rounded-t-lg'>פרופיל הצבא: <Link to={`/profile/${player.id}`} className={
                    player.rank_total === 1 ? 'border border-red-500 p-1' :
                    player.rank_total === 2 ? 'border border-yellow-500 p-1' :
                    player.rank_total === 3 ? 'border border-green-500 p-1' : ''
                  }>{player.nickname}</Link></h2>
      {/* <p className='bg-slate-600 widthpro p-5 mt-3'>ID: {player.id}</p> */}
      <p className='bg-black bg-opacity-20 text-xl widthpro p-5 mt-3 rounded-lg border-white border border-1 border-opacity-10'>עיר: {player.city}</p>
      <p className='bg-black bg-opacity-20 text-xl widthpro p-5 mt-3 rounded-lg border-white border border-1 border-opacity-10'>דירוג בעיר: {player.rank_city}</p>
      <p className='bg-black bg-opacity-20 text-xl widthpro p-5 mt-3 rounded-lg text-yellow-500 border-white border border-1 border-opacity-10'>זהב: {player.gold}</p>
      <p className='bg-black bg-opacity-20 text-xl widthpro p-5 mt-3 rounded-lg border-white border border-1 border-opacity-10'>חיילים: {player.rank_total}</p>
  <p className='bg-black bg-opacity-20 text-xl widthpro2 text-center p-5 mt-3 rounded-lg border-white border border-1 border-opacity-10'>
  שבט: <span className={
                    player.rank_total === 1 ? 'bg-red-500 p-1' : ''
                  }>
                    {player.clan ? `${player.clan}` : 'ללא שבט'}
                  </span>
</p>
      <p className='bg-black bg-opacity-20 text-xl widthpro2 text-center p-5 mt-3 border-white border border-1 border-opacity-10 rounded-lg'>סטטוס השבט: ללא סטטוס השבט</p>
      <p className='bg-black bg-opacity-20 text-xl widthpro2 text-center p-5 mt-3 border-white border border-1 border-opacity-10 rounded-lg'>סטטוס הצבא: ללא סטטוס הצבא</p>
      <div className='w-full mt-3'></div>
    </div>
  );
};

export default Profile;
