export function formatNumber(number: number | undefined | null) {
  if (number === undefined || number === null || isNaN(number)) {
    return 'Invalid number';
  }
  return number.toLocaleString();
}

export function formatNumberLetters(number: number) {
    const absNumber = Math.abs(number);
    if (absNumber >= 1e12) {
        return (number / 1e12).toFixed(1) + 't';
    }
    if (absNumber >= 1e9) {
        return (number / 1e9).toFixed(1) + 'b';
    }
    if (absNumber >= 1e6) {
        return (number / 1e6).toFixed(1) + 'm';
    }
    if (absNumber >= 1e3) {
        return (number / 1e3).toFixed(1) + 'k';
    }
    return number.toString();
}