import React, { useEffect } from 'react';
import { useAuth } from '../AuthContext';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import "./Home.scss"
import Menu from './Menu';
import Stats from "../components/Stats"
import EndOfSeasonComponent from '../components/Timers';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Playground from './Sidebar';
import Logo from "../images/logo.png"
import ScrollToTop from 'src/components/ScrollToTop';
import Footer from './Footer';
import SimpleSlider from 'src/components/Slick';

const Home: React.FC = () => {
    const { user } = useAuth();
    const navigate = useNavigate();

    const token = localStorage.getItem('token');
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            // Redirect to dashboard if user is already logged in
            // navigate('/dashboard');
        }
    }, []); // Empty dependency array ensures this runs only once on component mount
const location = useLocation() as any;
    return (
        <div className='flex flex-col justify-center items-center min-h-screen w-10/12'>
            <ScrollToTop />
            {token ? (
                <>
                <div className='flex flex-col md:flex-row w-widthcon sm:w-full '>
                    <Playground />
                    <div className='bg-black bg-opacity-80 container items-center flex flex-col flex-grow'>
                        <Stats />
                        <EndOfSeasonComponent />
                        
                        <SimpleSlider />
                        
                        
                        
                        
                       
                        <TransitionGroup component={null}>
                            <CSSTransition key={location.key} classNames="fade" timeout={0}>
                                <Outlet />
                            </CSSTransition>
                        </TransitionGroup>
                                <Footer />
                    </div>
                </div>
                            <div className='w-full bg-black bg-opacity-85 border border-l-0 border-r-0 border-b-0 border-white border-opacity-20 backdrop-blur-lg p-3 text-white text-lg grid grid-cols-2 px-5'>

                            <span>כל הזכויות שמורות 
                                <span className='t font-bold'> מלחמות קדושות</span>
                            </span>
                            <span></span>
                            </div>
                </>
            ) : (
                <div className='flex flex-col items-center justify-start w-full px-4'>
                    <div className='login bg-white text-black bg-no-repeat bg-contain bg-opacity-25 p-10 rounded flex flex-col items-center backdrop-blur-md border border-1 border-black border-opacity-20 shadow-lg'>
                        <header className='flex flex-col items-center'>
                            <img src={Logo} alt="Logo" width="250px" className='hover:scale-90 transition duration-500 mb-5'/>
                            <h1 className="text-center font-semibold">מלחמות קדושות - משחק דפדפן ישראלי</h1>
                            <p>בנה את הצבא החזק ביותר</p>
                        </header>
                        <div className="flex mt-7 gap-5 ">
                            <Link className='border border-1 bg-black bg-opacity-20 !text-white hover:bg-white hover:!text-black border-white transition-all px-4 py-1 rounded text-center font-semibold' to="/login">התחבר</Link>
                            <Link to="/register" className='border border-1 bg-black bg-opacity-20 !text-white hover:bg-white hover:!text-black border-white transition-all px-4 py-1 rounded text-center font-semibold'>הירשם</Link>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Home;