import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { jwtDecode } from "jwt-decode";
import BaseUrl from 'src/config';
const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();

  const token = localStorage.getItem('token');


  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${BaseUrl}/login`, {
        email,
        password
      });

      const { token, message } = response.data;

      if (message === 'Email not verified') {
        const { value: verificationCode } = await Swal.fire({
          title: 'אנא הזן קוד אימות',
          input: 'text',
          inputPlaceholder: 'קוד אימות',
          showCancelButton: true,
          confirmButtonText: 'אמת',
          cancelButtonText: 'ביטול',
          inputValidator: (value) => {
            if (!value) {
              return 'יש להזין קוד אימות';
            }
          }
        });
      
        if (verificationCode) {
          try {
            // Make request to verify endpoint with the provided code
            const verifyResponse = await axios.get(`${BaseUrl}/verify/${verificationCode}`);
            
            // Handle the response from the server
            const { message: verifyMessage } = verifyResponse.data;
            if (verifyMessage === 'Verification successful') {
              // If verification is successful, proceed with login
              localStorage.setItem('token', token);
              toast.success('המשתמש אומת בהצלחה, התחבר שנית');
              navigate('/');
            } else {
              // If verification fails, display an error message
              setErrorMessage('אימות נכשל. אנא נסה שוב.');
            }
          } catch (error) {
            console.error('Error verifying code:', error);
            setErrorMessage('אימות נכשל. אנא נסה שוב.');
          }
        } else {
          // If the user cancels the verification code prompt, do nothing
          return;
        }
      } else {
        // If the message is not 'Email not verified', proceed with login
        localStorage.setItem('token', token);
        toast.success('התחברת בהצלחה!');
        navigate('/');
    if (token) {
      const decodedToken: any = jwtDecode(token);
      const expirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds
      const currentTime = Date.now();
      const timeUntilExpiration = expirationTime - currentTime;
      console.log(timeUntilExpiration)
      if (timeUntilExpiration > 0) {
        setTimeout(() => {
          localStorage.removeItem('token');
          toast.info('התוקף של ההתחברות שלך פג. נא להתחבר שוב.');
          navigate('/login'); // Redirect to login page
        }, timeUntilExpiration);
      } else {
        localStorage.removeItem('token');
        toast.info('התוקף של ההתחברות שלך פג. נא להתחבר שוב.');
        navigate('/login'); // Redirect to login page
      }
    }
      }
    } catch (error: any) {
      console.error('Error logging in:', error.response?.data?.message);
      setErrorMessage(error.response?.data?.message || 'An error occurred during login.');
      toast.error('השם או הסיסמה אינם תקינים!');
    }
  };

  return (
    <div className='flex justify-center items-center h-screen'>
      <div className='bg-white text-black bg-no-repeat bg-contain bg-opacity-25 p-10 rounded flex flex-col items-center justify-center backdrop-blur-md border border-1 border-black border-opacity-20 shadow-lg'>
        <h2 className='text-3xl font-bold'>התחברות</h2>
        <form onSubmit={handleLogin} className='text-white font-bold flex flex-col'>
          <div>
            <input
              type="email"
              autoComplete='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder='איימל'
              className='bg-black backdrop-blur-xl bg-opacity-30 border border-white p-2 rounded text-black mt-3 pl-10 placeholder:text-white'
            />
          </div>
          <div className='relative mt-3 flex items-center w-11/12'>
            <input
            autoComplete='current-password'
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder='סיסמה'
              className='bg-black backdrop-blur-xl bg-opacity-30 border border-white p-2 rounded text-black pl-10 flex-grow placeholder:text-white'
            />
            <input
              type="checkbox"
              className='opacity-0 cursor-pointer' // Hide the checkbox
              checked={showPassword}
              onChange={() => setShowPassword(!showPassword)}
            />
            <span 
              className='text-white cursor-pointer absolute left-0 border-r pr-1'
              onClick={() => setShowPassword(!showPassword)} // Toggle showPassword on click
            >
              {showPassword ? <i className="fas fa-eye text-black"></i> : <i className="fas fa-eye-slash "></i>}
            </span>
          </div>
          <div className="flex mt-7 gap-5 w-full items-center justify-center">
            <button type="submit" className='border border-1 bg-black bg-opacity-20 !text-white hover:bg-white hover:!text-black border-white transition-all px-4 py-1 rounded text-center font-semibold'>התחבר</button>
            <Link to="/register" className='border border-1 bg-black bg-opacity-20 !text-white hover:bg-white hover:!text-black border-white transition-all px-4 py-1 rounded text-center font-semibold' style={{ display: 'inline-block', textDecoration: 'none' }}>הירשם</Link>
          </div>
        </form>

      </div>
    </div>
  );
};

export default Login;
