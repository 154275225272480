import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Icon from "../images/logo.png";
import BaseUrl from 'src/config';
import { getTimeDifference } from 'src/components/getTimeDiffrence';

const CreateNews = () => {
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [image, setImage] = useState<string>('');
    const [tags, setTags] = useState<any[]>([]);
    const [selectedTags, setSelectedTags] = useState<any[]>([]);
    const [livePreview, setLivePreview] = useState<string>('');

    // Fetch available tags from backend
    useEffect(() => {
        axios.get(`${BaseUrl}/tags`)
            .then(response => {
                setTags(response.data);
            })
            .catch(error => {
                console.error('Error fetching tags:', error);
            });
    }, []);

    // Function to handle tag selection
    const handleTagSelection = (tag: any) => {
        if (selectedTags.includes(tag)) {
            setSelectedTags(selectedTags.filter((selectedTag: any) => selectedTag !== tag));
        } else {
            setSelectedTags([...selectedTags, tag]);
        }
    };

    // Function to handle news creation
const handleCreateNews = () => {
    // Validate that the title is not empty
    if (!title) {
        console.error('Title cannot be empty');
        return;
    }

    // Prepare the data to be sent in the request body
    const requestData = {
        title: title,
        description: description,
        imageUrl: image,
        tags: selectedTags
    };

    // Send POST request to backend
    axios.post(`${BaseUrl}/news`, requestData)
        .then(response => {
            console.log('News created successfully:', response.data);
            // Clear form fields after successful submission
            setTitle('');
            setDescription('');
            setImage('');
            setSelectedTags([]);
            setLivePreview('');
        })
        .catch(error => {
            console.error('Error creating news:', error);
        });
};

// Update the image state when the user inputs an image URL


    // Function to update live preview
    useEffect(() => {
        const selectedTagObjects = selectedTags.map(tagId => tags.find(tag => tag.id === tagId));
    let previewContent = '';

    if (image && typeof image === 'object') {
        previewContent = `<img class="w-full rounded-xl" src="${URL.createObjectURL(image)}" alt="תמונת רקע חדשות" />`;
    } else if (typeof image === 'string') {
        previewContent = `<img class="w-full rounded-xl" src="${image}" alt="תמונת רקע חדשות" />`;
    }
        // Generate HTML for selected tags
        const selectedTagsHTML = selectedTagObjects.map((tag, index) => (
            `<span key="${tag.id}" class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 ml-2 ${index !== 0 ? 'ml-2' : ''}">${tag.tag_name}</span>`
        )).join('');

        setLivePreview(`
            <div class="max-w-sm rounded-xl overflow-hidden shadow-lg mb-5 border border-white border-opacity-10 backdrop-blur-lg">
                <div class='p-5'>
                    ${previewContent}
                </div>
                <div class="px-6 py-4">
                    <div class='flex items-center gap-2'>
                        <img src="${Icon}" width="64" alt="אייקון" />
                        <div class="font-bold text-xl">${title}
                            <p class="text-white text-base font-light">${getTimeDifference(new Date().toString())}</p>
                        </div>
                    </div>
                    <div class='w-full bg-white bg-opacity-10 h-px mt-4'></div>
                    ${description && `<p class="text-white text-base mt-5">${description}</p>`}
                    <div class="mt-4">
                        ${selectedTagsHTML}
                    </div>
                </div>
            </div>
        `);
    }, [title, description, selectedTags, tags, image]);


    return (
        <div className='w-widthcon flex flex-col items-center'>
            <h1 className='text-3xl mb-5 mt-5'>צור עדכון חדש</h1>
            <div>
                <label className='text-2xl'>כותרת:</label>
                <input className='border border-black border-dashed w-full bg-black bg-opacity-20 p-3 text-white' type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
            </div>
            <div>
                <label className='text-2xl'>תיאור:</label>
                <textarea className='border border-black border-dashed w-full bg-black bg-opacity-20 p-3 text-white' value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
            </div>
<input 
    className='border border-black border-dashed w-full bg-black bg-opacity-20 p-3 text-white'
    type="text" 
    onChange={(e) => {
        const imageUrl = e.target.value;
        setImage(imageUrl); // Set the image URL directly
    }} 
    placeholder="הכנס קישור לתמונת רקע" 
/>


            <div>
                <label>תגיות:</label>
                {tags.map(tag => (
                    <label key={tag.id} className='flex'>
                        <input type="checkbox" checked={selectedTags.includes(tag.id)} onChange={() => handleTagSelection(tag.id)} />
                        {tag.tag_name}
                    </label>
                ))}
            </div>
            <button className='mt-5 mb-5 bg-desert hover:bg-opacity-80 transition-all duration-200 p-3 rounded border-white border border-opacity-10' onClick={handleCreateNews}>יצירת עדכון</button>
            <div>
                <h2 className='text-2xl'>תצוגה מקדימה:</h2>
                <div dangerouslySetInnerHTML={{ __html: livePreview }} className="flex gap-5 justify-center items-center align-middle"></div>
            </div>
        </div>
    );
}

export default CreateNews;
