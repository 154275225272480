import React from 'react';

interface ClanSymbolProps {
  matrix: string[][];
  width: string;
  height: string;
}

const ClanSymbol: React.FC<ClanSymbolProps> = ({ matrix, width, height }) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(30, 1fr)',
        gridTemplateRows: 'repeat(30, 1fr)',
        width: width,
        height: height,
        gap: '0',
        padding: '0',
        margin: '0',
      }}
    >
      {matrix.map((row, rowIndex) =>
        row.map((color, colIndex) => (
          <div
            key={`${rowIndex}-${colIndex}`}
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: color,
            }}
          />
        ))
      )}
    </div>
  );
};

export default ClanSymbol;
