import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BaseUrl from 'src/config';
import { toast } from 'react-toastify';

const Settings: React.FC = () => {
    const [prevPassword, setPrevPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [vacationDays, setVacationDays] = useState(0);
    const [isVacationMode, setIsVacationMode] = useState(false);
    const token = localStorage.getItem('token');

    useEffect(() => {
        // Fetch the number of vacation days and vacation mode status from the API
        const fetchVacationData = async () => {
            try {
                const response = await axios.get(`${BaseUrl}/vacation-days`, {
                    headers: {
                        Authorization: token,
                    },
                });
                setVacationDays(response.data.vacationDays);
            } catch (error) {
                console.error('Failed to fetch vacation days:', error);
            }
        };

        // Fetch the vacation mode status
        const fetchVacationMode = async () => {
            try {
                const response = await axios.get(`${BaseUrl}/vacation`, {
                    headers: {
                        Authorization: token,
                    },
                });
                setIsVacationMode(response.data.vacation === 1);
            } catch (error) {
                console.error('Failed to fetch vacation mode:', error);
            }
        };

        fetchVacationData();
        fetchVacationMode();
    }, [token]);

    const handlePasswordChange = async (e: React.FormEvent) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            toast.error("הסיסמה החדשה והאימות סיסמה חדשה לא תואמות")
            return;
        }

        try {
            await axios.post(`${BaseUrl}/change-password`, 
                {
                    prevPassword,
                    newPassword, 
                },
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );
            toast.success("הסיסמה שונתה בהצלחה")

        } catch (error) {
            console.error(error);
                toast.error("שגיאה בשינוי הסיסמה")
        }
    };

    const handleVacationMode = async () => {
        if (!isVacationMode && vacationDays <= 0) {
                toast.info("נגמרו לך החופשות")
            return;
        }

        try {
            await axios.post(
                `${BaseUrl}/vacation-mode`, 
                { enterVacationMode: !isVacationMode },
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );
            if (isVacationMode) {
                toast.info("יצאת מחופשה")
            } else {
                setVacationDays(vacationDays - 1);
                toast.success("יצאת לחופשה")
            }
            setIsVacationMode(!isVacationMode);
        } catch (error) {
            console.error(error);
            alert('Failed to toggle vacation mode');
            toast.error("שגיאה בחופשות")

        }
    };

    return (
        <div className="w-widthcon">
            <h2 className='text-2xl font-bold my-5 text-center'>הגדרות</h2>
            <form onSubmit={handlePasswordChange} className="bg-black backdrop-blur-xl bg-opacity-30 border border-white border-opacity-20 p-3 rounded text-white flex-grow gap-3 flex flex-col w-full">
                <h3 className='text-2xl font-semibold'>שינוי סיסמה</h3>
                <div className='flex flex-row gap-3 flex-wrap'>
                    <input
                        type="password"
                        className="bg-black backdrop-blur-xl bg-opacity-30 border border-white p-2 rounded text-white pl-10 flex-grow placeholder:text-white"
                        placeholder='סיסמה חדשה'
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <input
                        type="password"
                        className="bg-black backdrop-blur-xl bg-opacity-30 border border-white p-2 rounded text-white pl-10 flex-grow placeholder:text-white"
                        placeholder='אימות סיסמה חדשה'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </div>
                <div className='flex'>
                    <input
                        type="password"
                        className="bg-black backdrop-blur-xl bg-opacity-30 border border-white p-2 rounded text-white pl-10 flex-grow placeholder:text-white"
                        placeholder='סיסמה נוכחית'
                        value={prevPassword}
                        onChange={(e) => setPrevPassword(e.target.value)}
                    />
                </div>
                <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    שנה סיסמה
                </button>
            </form>
            <div className="bg-black backdrop-blur-xl bg-opacity-30 border border-white border-opacity-20 p-3 rounded text-white flex-grow gap-3 flex flex-col w-full mt-5">
                <div className="flex gap-2 items-center">
                    <h3 className='text-2xl font-semibold'>מצב חופשה</h3>
                    <p className='text-wood'>(חופשות שנותרו {vacationDays})</p> 
                </div>
                <button onClick={handleVacationMode} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded disabled:bg-wood disabled:cursor-not-allowed" disabled={!isVacationMode && vacationDays <= 0}>
                    {isVacationMode ? 'חזור מחופשה' : 'צא לחופשה'}
                </button>
                <div className="text-sm my-2">
                <p>במצב חופשה הצבא מייצר 50% תפוקה ממה שהוא מייצר בעדכון רגיל.</p>
                <p>אפשר לצאת למצב חופשה 5 פעמים בכל עונה</p>
                </div>
                    
            </div>
        </div>
    );
};

export default Settings;
