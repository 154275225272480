import React from 'react'
import { Sidebar } from 'src/components/Hwadmin'

const Admin = () => {
  return (
    <>
      <Sidebar />
    </>
  )
}

export default Admin