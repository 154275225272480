import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import BaseUrl from '../config';
import { toast } from 'react-toastify';
const Register: React.FC = () => {
  const [email, setEmail] = useState('');
  const [checkbox, setCheckbox] = useState('');
    const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();

  const handleRegister = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${BaseUrl}/register`, {
        email,
        password
      });

      navigate('/');
      toast.success('המשתמש נוצר בהצלחה, יש לאשר במייל');

    } catch (error: any) {
      console.error('Error registering user:', error.response?.data?.message);
      setErrorMessage(error.response?.data?.message || 'An error occurred during registration.');
    }
  };

  return (
    <div className='flex justify-center items-center h-screen'>
    <div className='bg-white text-black bg-no-repeat bg-contain bg-opacity-25 p-10 rounded flex flex-col items-center backdrop-blur-md border border-1 border-black border-opacity-20 shadow-lg'>
      <h2 className='text-3xl font-bold'>הרשמה</h2>
      <form onSubmit={handleRegister} className='text-black font-bold'>
        
                <input
        type="checkbox"
        className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out ml-1"
        onChange={(e) => setCheckbox("e.target.value")}
            required
            value={checkbox}
      />
        קראתי ואני מסכים ל<Link to='/rules' className='text-gray-700 hover:text-gray-900'>חוקים</Link> של המשחק
        <div>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder='איימל'
            className='bg-black backdrop-blur-xl bg-opacity-30 border border-white p-2 rounded text-black pl-10 flex-grow placeholder:text-white'
          />
        </div>
        <div className='relative mt-3 flex items-center w-11/12'>
  <input
    type={showPassword ? 'text' : 'password'}
    value={password}
    onChange={(e) => setPassword(e.target.value)}
    required
    placeholder='סיסמה'
    className='bg-black backdrop-blur-xl bg-opacity-30 border border-white p-2 rounded text-black pl-10 flex-grow placeholder:text-white'
  />
  <input
    type="checkbox"
    className='opacity-0 cursor-pointer' // Hide the checkbox
    checked={showPassword}
    onChange={() => setShowPassword(!showPassword)}
  />
  <span 
    className='text-white cursor-pointer absolute left-0 border-r pr-1'
    onClick={() => setShowPassword(!showPassword)} // Toggle showPassword on click
  >
    {showPassword ? <i className="fas fa-eye text-black"></i> : <i className="fas fa-eye-slash "></i>}
  </span>
</div>

          <div className="flex mt-7 gap-5 w-full items-center justify-center">
            <button type="submit" className='border border-1 bg-black bg-opacity-20 !text-white hover:bg-white hover:!text-black border-white transition-all px-4 py-1 rounded text-center font-semibold'>הירשם</button>
            <Link to="/login" className='border border-1 bg-black bg-opacity-20 !text-white hover:bg-white hover:!text-black border-white transition-all px-4 py-1 rounded text-center font-semibold' style={{ display: 'inline-block', textDecoration: 'none' }}>התחבר</Link>
          </div>
      </form>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </div>
    </div>
  );
};

export default Register;
