import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import BaseUrl from 'src/config';
import { getTimeDifference } from './getTimeDiffrence';
interface Message {
  senderNickname: string;
  senderId: string;
  message_content: string;
  message_title: string;
  timestamp: string;
}

const SingleLetter = () => {
  const { id } = useParams<{ id: string }>(); // Add type annotation for id
  const [message, setMessage] = useState<Message | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchMessageDetails();
  }, [id]);

  const fetchMessageDetails = async () => {
    try {
      const token = localStorage.getItem('token');
      const email = token ? (jwtDecode(token) as any)?.email : undefined;


      if (token && email) {
        const response = await axios.get<Message>(`${BaseUrl}/messages/${id}`, {
          headers: {
            Authorization: token,
            email: email,
          },
        });
        setMessage(response.data);
      }
    } catch (error) {
      console.error('Error fetching message details:', error);
      setError('Message not found');
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!message) {
    return <div></div>;
  }

  return (
    
    <div className="w-widthcon flex flex-col">
        <div className="letters bg-black bg-opacity-30 backdrop-blur-lg rounded-lg border border-white border-opacity-10 mt-5 mb-5 flex justify-around text-center">
    <Link className="p-3 m-2 w-1/4 text-2xl bg-black bg-opacity-20 transition-all duration-100 rounded-md border border-white border-opacity-5" to="/letters/outbox">דואר יוצא</Link>
    <Link className="p-3 m-2 w-1/4 text-2xl bg-black bg-opacity-20 transition-all duration-100 rounded-md border border-white border-opacity-5" to="/letters/inbox">דואר נכנס</Link>
    <Link className="p-3 m-2 w-1/4 text-2xl bg-black bg-opacity-20 transition-all duration-100 rounded-md border border-white border-opacity-5" to="/letters/new_letter">שלח מכתב חדש</Link>
</div>

      <div className='bg-black bg-opacity-20 backdrop-blur-md border border-opacity-10 border-white w-full gap-5 p-5 flex flex-row rounded-xl sm:flex-col sm:justify-center mb-5'>
      <div className='text-4xl m-1 p-2 border-b border-stone-800'>
        {message.message_title}
        </div>
<div className='messageContent p-3' dangerouslySetInnerHTML={{ __html: message.message_content }} />

      <div className='flex justify-end'>
      <div className='p-5'>
        {message.senderId === '72' ? (
    <span className={`hover:underline ${message.senderNickname === 'Tester' ? 'bg-white' : ''}`}>
      {message.senderNickname === 'Tester' ? 'מנהל האתר' : <Link to={`/profile/${message.senderId}`}>{message.senderNickname}</Link>}
    </span>
  ) : (
    <span className={`${message.senderNickname === 'Tester' ? 'bg-white font-bold p-1 text-black m-2' : ''}`}>
      {message.senderNickname === 'Tester' ? 'מנהל האתר' : <Link to={`/profile/${message.senderId}`}>{message.senderNickname}</Link>}
    </span>
  )}, {getTimeDifference(message.timestamp)}
      </div>
      </div>
    </div></div>
  );
};

export default SingleLetter;
