import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Bg1 from "../images/news.jpg";
import Icon from "../images/logo.png";
import NoImage from "../images/error.png"; // Import the default image
import { Link } from 'react-router-dom';
import BaseUrl from 'src/config';
import { getTimeDifference } from './getTimeDiffrence';

interface NewsArticle {
  id: number;
  title: string;
  description: string;
  image_url?: string;
  date_published: string;
  tags: string[];
}

const News = () => {
  const [news, setNews] = useState<NewsArticle[]>([]);

  useEffect(() => {
    // Fetch news articles from backend
    axios.get<NewsArticle[]>(`${BaseUrl}/news`)
      .then(response => {
        setNews(response.data);
      })
      .catch(error => {
        console.error('Error fetching news articles:', error);
      });
  }, []);


  return (
    <>
      <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-3.5 aspect-auto">
        {news.map(article => (
          <div key={article.id} className="transition-all hover:scale-98 duration-500 w-1/1 rounded-xl overflow-hidden shadow-lg mb-5 border border-white border-opacity-10 backdrop-blur-lg">
            <Link to={`/news/${article.id}`}>
              <div className='p-2'>
                <img
                  className="w-full rounded-lg h-28 hover:max-h-screen object-cover object-top"
                  src={article.image_url || NoImage}
                  onError={(e) => { e.currentTarget.src = NoImage; }}
                  alt={"תמונת רקע חדשות"}
                />
              </div>
              <div className="px-6 py-4">
                <div className='flex items-center gap-2 sm:flex-col sm:text-center sm:flex-wrap lg:flex-row lg:justif'>
                  <img src={Icon} className='w-10' alt="אייקון" />
                  <div className="font-bold text-xl sm:text-sm">{article.title}
                    <p className="text-white text-base font-light sm:text-sm">{getTimeDifference(article.date_published)}</p>
                  </div>
                </div>
                <div className='w-full bg-white bg-opacity-10 h-px mt-4 '></div>
                {article.description && <p className="text-white sm:text-sm text-base mt-5" dangerouslySetInnerHTML={{ __html: article.description }} />}

                
                <div className='w-full bg-white bg-opacity-10 h-px mt-4 '></div>
                <div className="mt-2 p-1 text-center bg-white bg-opacity-10 backdrop-blur-xl text rounded-lg">נושאים</div>
                <div className="mt-2">
                  {article.tags && article.tags.map(tag => (
                    <span key={tag} className="inline-block border border-gray-200 rounded-lg px-3 py-1 text-sm font-semibold text-white border-opacity-20 mr-2 mb-2">{tag}</span>
                  ))}
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
}

export default News;
