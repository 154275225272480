import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { jwtDecode } from "jwt-decode";
import "./Armies.scss";
import BaseUrl from 'src/config';
import { formatNumber } from 'src/components/FormatNumber';
import ClanSymbol from 'src/components/ClanSymbol';

interface Player {
  id: string;
  email: string;
  nickname: string;
  gold: number;
  rank_city: number;
  rank_total: number;
  soliders: number;
  isAdmin: boolean;
  city: string;
  clan: string;
  symbol: string | null; // Include the symbol matrix as a JSON string or null
}

const Armies: React.FC = () => {
  const [players, setPlayers] = useState<Player[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [userEmail, setUserEmail] = useState<string>('');
  const [city, setCity] = useState<string>('');

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');
      let email = '';

      if (token) {
        const decodedToken: any = jwtDecode(token);
        setUserEmail(decodedToken.email);
        email = decodedToken.email;
      }

      const response = await axios.get(`${BaseUrl}/selectPlayers`, {
        headers: {
          Authorization: token,
        },
      });

      const { city, players } = response.data;

      const sortedPlayers = players.sort((a: { rank_city: number; }, b: { rank_city: number; }) => a.rank_city - b.rank_city);
      setPlayers(sortedPlayers);
      setCity(city);

    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.error('Error fetching player information:', error.response?.data.message);
      } else {
        console.error('Unknown error:', error);
      }
    }
  };

  useEffect(() => {
    fetchData();

    const interval = setInterval(fetchData, 5000);

    return () => clearInterval(interval);
  }, []);

  const filteredPlayers = players.filter(player =>
    player.nickname && player.nickname.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const resultsPerPage = 20;
  const totalResults = filteredPlayers.length;
  const totalPages = Math.ceil(totalResults / resultsPerPage);
  const indexOfLastPlayer = currentPage * resultsPerPage;
  const indexOfFirstPlayer = indexOfLastPlayer - resultsPerPage;
  const currentPlayers = filteredPlayers.slice(indexOfFirstPlayer, indexOfLastPlayer);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className='w-widthcon flex flex-col items-center text-right'>
      <h2 className='text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white mt-10 mb-10'>
        דירוג צבאות {city}
      </h2>
      <div className="player-list w-full">
        <div className="flex items-center justify-center gap-10 w-full mb-4">
          <input
            type="text"
            placeholder="חיפוש שחקן בעיר"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            className='border border-black border-dashed w-full rounded-lg text-white bg-black bg-opacity-30 p-3 placeholder:text-white'
          />
          <input
            type="number"
            min="1"
            max={totalPages}
            placeholder='עמוד...'
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                const pageNumber = parseInt(e.currentTarget.value);
                if (pageNumber >= 1 && pageNumber <= totalPages) {
                  handlePageChange(pageNumber);
                }
              }
            }}
            onChange={e => {
              const value = e.target.value;
              if (!value) {
                setCurrentPage(1);
              } else {
                setCurrentPage(parseInt(value));
              }
            }}
            className='border border-black border-dashed rounded-lg w-full text-white bg-black bg-opacity-30 p-3 placeholder:text-white'
          />
        </div>
        <div className='max-lg:overflow-x-scroll'>
          <table className='w-full'>
            <thead>
              <tr>
                <th className='p-3 bg-black backdrop-blur-md bg-opacity-50 w-1/12 rounded-tr-lg'>דירוג</th>
                <th className='p-3 bg-black backdrop-blur-md bg-opacity-50 w-1/12'></th>
                <th className='p-3 bg-black backdrop-blur-md bg-opacity-50 w-1/5'>צבא</th>
                <th className='p-3 bg-black backdrop-blur-md bg-opacity-50 w-1/5'>זהב</th>
                <th className='p-3 bg-black backdrop-blur-md bg-opacity-50 w-1/5'>חיילים</th>
                <th className='p-3 bg-black backdrop-blur-md bg-opacity-50 w-1/5 rounded-tl-lg'>שבט</th>
              </tr>
            </thead>
            <tbody>
              {currentPlayers.map((player, index) => {
                // Declare and initialize symbolMatrix from the player's symbol
                const symbolMatrix = player.symbol ? JSON.parse(player.symbol) : null;

                return (
                  <tr 
                    key={index} 
                    className={`hover:bg-white hover:bg-opacity-10 transition-all duration-100 text-white font-semibold ${
                      index % 2 === 0 ? 'bg-black bg-opacity-20 backdrop-blur-lg' : 'bg-black bg-opacity-40 backdrop-blur-lg'
                    } ${
                      player.email === userEmail ? player.rank_total === 1 ? 'border border-y-2 border- border-x-0 bg-black border-wood border-opacity-80' :
                      player.rank_total === 2 ? 'border border-gold border-y-1 border-x-0 bg-me' :
                      player.rank_total === 3 ? 'border border-food  border-t-2 border-x-0 bg-me' : 'border-0 border-b bg-me' : ''
                    }`} 
                    {...(player.email === userEmail ? { 'data-tooltip-id': 'me' } : {})}
                  >

                    <td className='p-3 w-1/12'>
                      <span className={
                        player.rank_total === 1 ? 'p-3 py-1 rounded-full bg-wood' :
                        player.rank_total === 2 ? 'p-3 py-1 rounded-full bg-gold' :
                        player.rank_total === 3 ? 'p-3 py-1 rounded-full bg-food' : 'p-3 py-1 rounded-full bg-white bg-opacity-10'
                      }>
                        {player.rank_city}
                      </span>
                    </td>
                    <td className='p-3 w-fit flex items-center justify-center'>
                      <i className="fa fa-power-off text-bold text-blue-500"  data-tooltip-id="resdef"></i>
                      <i className="fa fa-power-off text-bold text-red-500" data-tooltip-id="soldef"></i>
                    </td>
                    <td className='p-3 w-1/5'>
                      <Link data-tooltip-id="profile" to={`/profile/${player.id}`} className={
                        player.rank_total === 1 ? 'no-underline hover:underline font-extrabold border-2 border-wood p-1' :
                        player.rank_total === 2 ? 'no-underline hover:underline font-extrabold border-2 border-gold p-1' :
                        player.rank_total === 3 ? 'no-underline hover:underline font-extrabold border-2 border-food p-1' : 'no-underline hover:underline text-nickname'
                      }>
                        {player.nickname}
                      </Link>
                      {player.isAdmin ? (
                        <>
                          <span data-tooltip-id="gamemaster" className='mr-5 border-2 border-green-500 text-green-500 p-1' >מנהל משחק</span>
                        </>
                      ) : null}
                    </td>
                    <td className='p-3 w-1/5'>{formatNumber(player.gold)}</td>
                    <td className='p-3 w-1/5'>{player.soliders}</td>
<td className='p-3 w-1/5'>
  {symbolMatrix ? (
    <div className="flex items-center ">
      <ClanSymbol matrix={symbolMatrix} width={"30px"} height={"30px"} />
      <span className={player.clan === "ישראל" ? 'mr-2 p-1 bg-red-500' : 'mr-2 p-1'}>
        {player.clan ? `${player.clan}` : 'ללא שבט'}
      </span>
    </div>
  ) : (
    <span className={player.rank_total === 1 ? 'bg-red-500 p-1 text-white' : ''}>
      {player.clan ? `${player.clan}` : 'ללא שבט'}
    </span>
  )}
</td>

                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="flex justify-center mt-4 mb-4">
          {Array.from(Array(totalPages).keys()).map(page => (
            <button 
              key={page} 
              onClick={() => handlePageChange(page + 1)} 
              className={`mx-1 px-3 py-1 rounded-md ${currentPage === page + 1 ? 'bg-white bg-opacity-20 backdrop-blur-md text-white' : 'bg-gray-300 text-gray-700 hover:bg-gray-400'}`}
            >
              {page + 1}
            </button>
          ))}
        </div>

        <ReactTooltip
          style={{ backgroundColor: "rgb(255, 0, 0)", color: "#fff" }}
          id="me"
          place="left"
          variant="info"
          content="הכינוי שלך במשחק"
        />
        <ReactTooltip
          style={{ backgroundColor: "#000", color: "#fff" }}
          id="profile"
          place="right"
          variant="info"
          content="צפה בפרופיל"
        />
        <ReactTooltip
          style={{ backgroundColor: "#3b82f6", color: "#fff" }}
          id="resdef"
          place="right"
          variant="info"
          content="מגן משאבים"
        />
        <ReactTooltip
          style={{ backgroundColor: "#ef4444", color: "#fff" }}
          id="soldef"
          place="right"
          variant="info"
          content="מגן חיילים"
        />
        <ReactTooltip
          style={{ backgroundColor: "#22c55e", color: "#fff" }}
          id="gamemaster"
          place="right"
          variant="info"
          content="אי אפשר לתקוף שחקן זה"
        />
      </div>
    </div>
  );
};

export default Armies;
