import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import BaseUrl from 'src/config';
import { formatNumber } from 'src/components/FormatNumber';
interface Weapon {
    id: string;
    weapon_name: string;
    weapon_type: string;
    weapon_power: number;
    weapon_amount: number;
    weapon_cost_gold: number;
    weapon_cost_iron: number;
    weapon_cost_wood: number;
    weapon_cost_food: number;
}

const BuyspyWeapons: React.FC = () => {
    const navigate = useNavigate();
    const [weapons, setWeapons] = useState<Weapon[]>([]);
    const [amounts, setAmounts] = useState<{ [key: string]: string }>({});
    const [message, setMessage] = useState<string>('');
    const [error, setError] = useState<string>('');
const handleAmountChange = (weaponId: string, value: string) => {
    setAmounts({ ...amounts, [weaponId]: value });
};
    useEffect(() => {
    const fetchWeapons = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get<Weapon[]>(`${BaseUrl}/weapons/spy`, {
                headers: {
                    Authorization: token,
                },
            });

            // Sort the weapons array by weapon_id
            const sortedWeapons = response.data.sort((a, b) => parseInt(a.id) - parseInt(b.id));

            setWeapons(sortedWeapons);
            const defaultAmounts: { [key: string]: string } = {};
            sortedWeapons.forEach((weapon: Weapon) => {
                defaultAmounts[weapon.id] = ''; // Set initial value to empty string
            });
            setAmounts(defaultAmounts);
            setMessage('');
            setError('');
        } catch (error) {
            console.error('Failed to fetch weapons:', error);
            setMessage('');
            setError('Failed to fetch weapons');
        }
    };
    fetchWeapons();
}, []);

    const handleBuyspyWeapons = async () => {
    try {
        const token = localStorage.getItem('token');
        
        // Filter out weapons with empty amounts
        const requestData = Object.entries(amounts)
            .filter(([weaponId, amount]) => amount !== '')
            .map(([weaponId, amount]) => ({ weaponId, amount }));

        const response = await axios.post(`${BaseUrl}/weapons/buy/spy`, requestData, {
            headers: {
                Authorization: token,
            },
        });
        setMessage(response.data.message);

        setError('');
                setTimeout(() => {
            navigate('/dashboard');
        }, 2000);
    } catch (error) {
        console.error('Failed to buy weapons:', error);
        setMessage('');
        setError('אין ברשותך מספיק משאבים.');
    }
};


    return (
        <div className='w-widthcon'>
            <div className="letters bg-desert mt-5 mb-5 flex justify-around text-center">
        <Link className='p-3 m-2 w-1/4 text-2xl  hover:bg-black hover:bg-opacity-20 transition-all duration-100' to="/weapons/attack">נשקים להתקפה</Link>
        <Link className='p-3 m-2 text-2xl  w-1/4 transition-all  hover:bg-black hover:bg-opacity-20 duration-100' to="/weapons/defense">נשקים להגנה</Link>
        <Link className='p-3 m-2 text-2xl  bg-black bg-opacity-20 w-1/4 transition-all duration-100' to="/weapons/spy">נשקים לריגול</Link>
      </div>
            {message && <div className='w-full bg-green-600 text-white p-3'>{message}</div>}
            {error && <div className='w-full bg-red-600 text-white p-3'>{error}</div>}
            <table className='w-full mt-5'>
                <thead>
                    <tr className='desert'>
                        <th className='p-3'>שם הנשק</th>
                        <th className='p-3'>זהב</th>
                        <th className='p-3'>עץ</th>
                        <th className='p-3'>ברזל</th>
                        <th className='p-3'>אוכל</th>
                        <th className='p-3'>כח הנשק</th>
                        <th className='p-3'>ברשותך</th>
                        <th className='p-3'>קנה נשק</th>
                    </tr>
                </thead>
                <tbody>
                    {weapons.map((weapon) => (
    <tr 
        key={weapon.id} 
        className={`hover:bg-evenhover transition-all duration-100 text-white font-semibold  ${
            Number(weapon.id) % 2 === 0 ? 'bg-even' : 'bg-even2'
        } text-center`}
    >
                            <td className='p-3 '>{weapon.weapon_name}</td>
                            <td className='p-3 text-gold'>{formatNumber(weapon.weapon_cost_gold)}</td>
                            <td className='p-3 text-wood'>{formatNumber(weapon.weapon_cost_wood)}</td>
                            <td className='p-3 text-iron'>{formatNumber(weapon.weapon_cost_iron)}</td>
                            <td className='p-3 text-food'>{formatNumber(weapon.weapon_cost_food)}</td>
                            <td className='p-3'>{formatNumber(weapon.weapon_power)}</td>
                            <td className='p-3'>{formatNumber(weapon.weapon_amount)}</td>
                            <td className='p-3'>
                                <input
                                    className='bg-black bg-opacity-20 mx-3 p-2 border border-dashed text-white text-center placeholder-white w-20 border-desert'
                                    // type="number"
                                    placeholder="0"
                                    onChange={(e) => handleAmountChange(weapon.id, e.target.value)}
                                />
                            {/* <button onClick={handleBuyspyWeapons} className='px-5 py-2 bg-diamond font-bold'>קנה נשק</button> */}
                            <button onClick={handleBuyspyWeapons} className="relative inline-flex items-center justify-center p-4 px-5 py-2 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out shadow-xl group hover:ring-1 hover:ring-desert">
  <span className="absolute inset-0 w-full h-full bg-gradient-to-br from-yellow-400 via-yellow-500 to-yellow-600"></span>
  <span className="absolute bottom-0 right-0 block w-64 h-64 mb-32 mr-4 transition duration-500 origin-bottom-left transform rotate-45 translate-x-24 bg-yellow-600 opacity-30 group-hover:rotate-90 ease"></span>
  <span className="relative text-black">קנה נשק</span>
</button>

                            </td>
    </tr>
))}
                </tbody>
            </table>
        </div>
    );
};

export default BuyspyWeapons;
