import React, { useState } from 'react';
import BaseUrl from 'src/config';
import { SketchPicker } from 'react-color';

interface PixelGridProps {
  onSave: (matrix: string[][]) => void;
}

// PixelGrid Component
const PixelGrid: React.FC<PixelGridProps> = ({ onSave }) => {
  const [grid, setGrid] = useState<string[][]>(Array(30).fill(Array(30).fill('#ffffff')));
  const [selectedColor, setSelectedColor] = useState<string>('#000000');
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [lastColors, setLastColors] = useState<string[]>([]);

  const handlePixelClick = (rowIndex: number, colIndex: number) => {
    const newGrid = grid.map((row, rIdx) =>
      row.map((color, cIdx) => {
        if (rIdx === rowIndex && cIdx === colIndex) {
          return selectedColor;
        }
        return color;
      })
    );
    setGrid(newGrid);
    updateLastColors(selectedColor);
  };

  const handleMouseDown = (rowIndex: number, colIndex: number) => {
    setIsDragging(true);
    handlePixelClick(rowIndex, colIndex);
  };

  const handleMouseOver = (rowIndex: number, colIndex: number) => {
    if (isDragging) {
      handlePixelClick(rowIndex, colIndex);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const updateLastColors = (color: string) => {
    setLastColors((prevColors) => {
      const newColors = [color, ...prevColors.filter((c) => c !== color)];
      return newColors.slice(0, 10);
    });
  };

  const handleSave = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found!');
      return;
    }

    // Send the grid matrix to the server
    const response = await fetch(`${BaseUrl}/save-symbol`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ symbol: grid, nickname: 'Tester' }),
    });

    if (response.ok) {
      console.log('Grid matrix saved successfully!');
    } else {
      console.error('Failed to save grid matrix:', await response.text());
    }
  };

const colLabels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%'.split('').slice(0, grid.length); 

  const rowLabels = Array.from({ length: grid[0].length }, (_, i) => i + 1);

  return (
<div onMouseUp={handleMouseUp} style={{ userSelect: 'none' }}>
<input
  type="color"
  value={selectedColor}
  onChange={(e) => setSelectedColor(e.target.value)}
  style={{
    userSelect: 'none', // Prevents text selection
    width: '40px', // Adjust width
    height: '40px', // Adjust height
    padding: '5px',
    border: '2px solid rgb(0 0 0 / 30%)', // Border styling
    borderRadius: '8px', // Rounded corners
    cursor: 'pointer', // Pointer cursor for better UX
    backgroundColor: '#f0f0f0', // Light background
  }}
/>
      <div>
        <div className="last-colors">
          <h3>10 צבעים אחרונים</h3>
          <div style={{ display: 'flex', gap: '5px' }}>
            {lastColors.map((color, index) => (
              <div
                key={index}
                onClick={() => setSelectedColor(color)}
style={{
  backgroundColor: color,
  width: '30px',
  height: '30px',
  border: '1px solid rgb(0 0 0 / 45%)',
  cursor: 'pointer',
  borderRadius: '50px',
}}

              />
            ))}
          </div>
        </div>

        {/* Column Labels */}
<div style={{ display: 'flex', marginLeft: '0px', direction: 'ltr' }}>
  {colLabels.map((label) => (
    <div key={label} style={{ width: '30px', textAlign: 'center', direction: 'ltr' }}>
      {label}
    </div>
  ))}
</div>

        {/* Pixel Grid with Row Labels */}
        <div id="pixel-grid">
          {grid.map((row, rowIndex) => (
            <div key={rowIndex} style={{ display: 'flex', alignItems: 'center' }}>
              {/* Row Label */}
              <div style={{ width: '30px', textAlign: 'center', fontWeight: 'bold', direction: 'ltr' }}>
                {rowLabels[rowIndex]}
              </div>
              {/* Grid Row */}
              {row.map((color, colIndex) => (
                <div
                  key={colIndex}
                  onMouseDown={() => handleMouseDown(rowIndex, colIndex)}
                  onMouseOver={() => handleMouseOver(rowIndex, colIndex)}
                  style={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: color,
                    border: '1px solid #ccc',
                    cursor: 'pointer',
                  }}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
      <button onClick={handleSave}>שמור</button>
    </div>
  );
};

// ClanPage Component
interface ClanPageProps {
  savedImage: string | null;
}

const ClanPage: React.FC<ClanPageProps> = ({ savedImage }) => {
  return (
    <div>
      <h1>Clan Symbol</h1>
      {savedImage ? (
        <img src={savedImage} alt="Clan Symbol" />
      ) : (
        <p>No symbol saved yet.</p>
      )}
    </div>
  );
};

// ClanMark Component
const ClanMark = () => {
  const [clanSymbol, setClanSymbol] = useState<string | null>(null);

  const handleSave = (matrix: string[][]) => {
    console.log('Matrix saved:', matrix);
  };

  return (
    <div className='mt-5'>
      <h1>תפריט צבעים</h1>
      <PixelGrid onSave={handleSave} />
      {clanSymbol && <ClanPage savedImage={clanSymbol} />}
    </div>
  );
};

export default ClanMark;
