import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BaseUrl from 'src/config';
import Nickname from './../components/Nickname';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getTimeDifference } from 'src/components/getTimeDiffrence';

interface Order {
  name: string;
  id: number;
  user_id: number;
  nickname: string;
  package_id: number;
  diamonds: number;
  turns: number;
  price: number;
  discount: number;
  successful: boolean;
  error_message: string | null;
  timestamp: string;
}

interface Package {
  id: number;
  name: string;
  diamonds: number;
  turns: number;
  price: number;
  discount: number;
  active: number; // Add the active field
}

function Orders() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<'orders' | 'packages' | 'newpackage'>('orders');
  const [orders, setOrders] = useState<Order[]>([]);
  const [packages, setPackages] = useState<Package[]>([]);
  const [editingPackage, setEditingPackage] = useState<Package | null>(null);
  const [newPackage, setNewPackage] = useState<Package>({
    id: 0,
    name: "0",
    diamonds: 0,
    turns: 0,
    price: 0,
    discount: 0,
    active: 0
  });

  useEffect(() => {
    axios.get(`${BaseUrl}/orders`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    })
      .then(response => {
        setOrders(response.data);
        console.log(response.data)
      })
      .catch(error => {
        console.error('There was an error fetching the orders!', error);
      });

    axios.get(`${BaseUrl}/adminpackages`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    })
      .then(response => {
        setPackages(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the packages!', error);
      });
  }, []);

  const handleEditPackage = (pkg: Package) => {
    setEditingPackage(pkg);
  };

  const handleSavePackage = () => {
    if (editingPackage) {
      axios.put(`${BaseUrl}/packages/${editingPackage.id}`, editingPackage, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
      })
        .then(response => {
          setPackages(packages.map(pkg => pkg.id === editingPackage.id ? editingPackage : pkg));
          setEditingPackage(null);
        })
        .catch(error => {
          console.error('There was an error updating the package!', error);
        });
    }
  };

  const handleDeletePackage = (pkgId: number) => {
    axios.delete(`${BaseUrl}/packages/${pkgId}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    })
      .then(response => {
        setPackages(packages.filter(pkg => pkg.id !== pkgId));
      })
      .catch(error => {
        console.error('There was an error deleting the package!', error);
      });
  };

  const handleAddPackage = () => {
    axios.post(`${BaseUrl}/packages`, newPackage, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    })
      .then(response => {
        setPackages([...packages, response.data]);
        setNewPackage({ name: '0', id: 0, diamonds: 0, turns: 0, price: 0, discount: 0, active: 0 });
        navigate('./')
        toast.success("החבילה נוצרה בהצלחה")
      })
      .catch(error => {
        console.error('There was an error adding the package!', error);
      });
  };

  const handleToggleActive = (pkg: Package) => {
    const updatedPackage = { ...pkg, active: pkg.active === 1 ? 0 : 1 };
    const token = localStorage.getItem("token")
    axios.put(`${BaseUrl}/packages/${pkg.id}/active`, updatedPackage, {
      headers: { Authorization: token }
    })
      .then(response => {
        setPackages(packages.map(p => p.id === pkg.id ? updatedPackage : p));
      })
      .catch(error => {
        console.error('There was an error updating the package active status!', error);
      });
  };



  return (
    <div className="container mx-auto w-widthcon mt-6 text-center">
      <div className="flex justify-center mb-6">
        <button
          className={`px-4 py-2 mx-2 ${activeTab === 'orders' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'} rounded`}
          onClick={() => setActiveTab('orders')}
        >
          רכישות
        </button>
        <button
          className={`px-4 py-2 mx-2 ${activeTab === 'packages' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'} rounded`}
          onClick={() => setActiveTab('packages')}
        >
          חבילות
        </button>
        <button
          className={`px-4 py-2 mx-2 ${activeTab === 'newpackage' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'} rounded`}
          onClick={() => setActiveTab('newpackage')}
        >
          חבילה חדשה
        </button>
      </div>
      <div>
        {activeTab === 'orders' && (
          <div>
            <h2 className="text-2xl font-bold mb-4">רכישות</h2>
            <table className="min-w-full bg-white text-black">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b">שם החבילה</th>
                  <th className="py-2 px-4 border-b">רכישה מספר</th>
                  <th className="py-2 px-4 border-b">שחקן</th>
                  <th className="py-2 px-4 border-b">חבילה</th>
                  <th className="py-2 px-4 border-b">יהלומים</th>
                  <th className="py-2 px-4 border-b">תורות</th>
                  <th className="py-2 px-4 border-b">מחיר</th>
                  <th className="py-2 px-4 border-b">הנחה</th>
                  <th className="py-2 px-4 border-b">סטטוס</th>
                  <th className="py-2 px-4 border-b">שגיאה</th>
                  <th className="py-2 px-4 border-b">זמן</th>
                </tr>
              </thead>
              <tbody>
                {orders.map(order => (
                  <tr key={order.id}>
                    <td className="py-2 px-4 border-b">{order.name}</td>
                    <td className="py-2 px-4 border-b">{order.id}</td>
                    <td className="py-2 px-4 border-b">{order.nickname}</td>
                    <td className="py-2 px-4 border-b">{order.package_id}</td>
                    <td className="py-2 px-4 border-b">{order.diamonds}</td>
                    <td className="py-2 px-4 border-b">{order.turns}</td>
                    <td className="py-2 px-4 border-b">{order.price}</td>
                    <td className="py-2 px-4 border-b">{order.discount}</td>
                    <td className="py-2 px-4 border-b">{order.successful ? 'תשלום בוצע' : 'נכשל'}</td>
                    <td className="py-2 px-4 border-b">{order.error_message}</td>
                    <td className="py-2 px-4 border-b">{getTimeDifference(order.timestamp)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {activeTab === 'packages' && (
          <div>
            <h2 className="text-2xl font-bold mb-4">חבילות</h2>
            <table className="min-w-full bg-white text-black mb-4">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b">חבילה מספר</th>
                  <th className="py-2 px-4 border-b">שם החבילה</th>
                  <th className="py-2 px-4 border-b">יהלומים</th>
                  <th className="py-2 px-4 border-b">תורות</th>
                  <th className="py-2 px-4 border-b">מחיר</th>
                  <th className="py-2 px-4 border-b">הנחה</th>
                  <th className="py-2 px-4 border-b">פעולות</th>
                </tr>
              </thead>
              <tbody>
                {packages.map(pkg => (
                  <tr key={pkg.id}>
                    <td className="py-2 px-4 border-b">{pkg.id}</td>
                    <td className="py-2 px-4 border-b">{pkg.name}</td>
                    <td className="py-2 px-4 border-b">{pkg.diamonds}</td>
                    <td className="py-2 px-4 border-b">{pkg.turns}</td>
                    <td className="py-2 px-4 border-b">{pkg.price}</td>
                    <td className="py-2 px-4 border-b">{pkg.discount}</td>
                    <td className="py-2 px-4 border-b flex flex-row justify-center gap-3">
                      <button 
                        onClick={() => handleEditPackage(pkg)}
                        className="bg-yellow-500 text-white px-2 py-1 rounded mr-2"
                      >
                        עריכה
                      </button>
                      <button 
                        onClick={() => handleDeletePackage(pkg.id)}
                        className="bg-red-500 text-white px-2 py-1 rounded"
                      >
                        מחיקה
                      </button>
                      <div className="inline-flex items-center">
  <label className="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor="customStyle">
    <input type="checkbox" 
      checked={pkg.active === 1}
      onChange={() => handleToggleActive(pkg)}
      className="before:content[''] peer relative h-8 w-8 cursor-pointer appearance-none rounded-full border border-red-900 bg-red-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-green-900 checked:bg-green-600 checked:before:bg-gray-900 hover:scale-105 hover:before:opacity-0 "
      id="customStyle"  />
    <span
      className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
      <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
        stroke="currentColor" stroke-width="1">
        <path fill-rule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clip-rule="evenodd"></path>
      </svg>
    </span>
  </label>
</div>

                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {editingPackage && (
              <div className="mb-4">
                <h3 className="text-xl font-bold mb-2">ערוך חבילה</h3>
                <input
                  type="string"
                  placeholder="שם החבילה"
                  value={editingPackage.name}
                  onChange={e => setEditingPackage({ ...editingPackage, name: e.target.value })}
                  className="border p-2 mb-2 w-full text-black"
                />

                <input
                  type="number"
                  placeholder="יהלומים"
                  value={editingPackage.diamonds}
                  onChange={e => setEditingPackage({ ...editingPackage, diamonds: +e.target.value })}
                  className="border p-2 mb-2 w-full text-black"
                />
                <input
                  type="number"
                  placeholder="תורות"
                  value={editingPackage.turns}
                  onChange={e => setEditingPackage({ ...editingPackage, turns: +e.target.value })}
                  className="border text-black p-2 mb-2 w-full"
                />
                <input
                  type="number"
                  placeholder="מחיר"
                  value={editingPackage.price}
                  onChange={e => setEditingPackage({ ...editingPackage, price: +e.target.value })}
                  className="border p-2 mb-2 w-full text-black"
                />
                <input
                  type="number"
                  placeholder="הנחה"
                  value={editingPackage.discount}
                  onChange={e => setEditingPackage({ ...editingPackage, discount: +e.target.value })}
                  className="border p-2 mb-2 w-full text-black"
                />
                <button
                  onClick={handleSavePackage}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  שמור חבילה
                </button>
              </div>
            )}
          </div>
        )}
        {activeTab === 'newpackage' && (
          <div className="mt-4">
            <h3 className="text-xl font-bold mb-2">חבילה חדשה</h3>
            <input
              type="string"
              placeholder="שם החבילה"
              onChange={e => setNewPackage({ ...newPackage, name: e.target.value })}
              className="border p-2 mb-2 w-full text-black"
            />
            <input
              type="number"
              placeholder="יהלומים"
              onChange={e => setNewPackage({ ...newPackage, diamonds: +e.target.value })}
              className="border p-2 mb-2 w-full text-black"
            />
            <input
              type="number"
              placeholder="תורות"
              onChange={e => setNewPackage({ ...newPackage, turns: +e.target.value })}
              className="border p-2 mb-2 w-full text-black"
            />
            <input
              type="number"
              placeholder="מחיר"
              onChange={e => setNewPackage({ ...newPackage, price: +e.target.value })}
              className="border p-2 mb-2 w-full text-black"
            />
            <input
              type="number"
              placeholder="הנחה"
              onChange={e => setNewPackage({ ...newPackage, discount: +e.target.value })}
              className="border p-2 mb-2 w-full text-black"
            />
            <button
              onClick={handleAddPackage}
              className="bg-green-500 text-white px-4 py-2 rounded"
            >
              הוסף חבילה
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Orders;
