import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getTimeDifference } from 'src/components/getTimeDiffrence';
import BaseUrl from 'src/config';
interface Article {
  id: number;
  title: string;
  description: string;
  image_url: string;
  date_published: string;
    tags: string[];
  // Add more properties if necessary
}


function SingleNew() {
  const { id } = useParams<{ id: string }>(); // Extracting the 'id' parameter from the URL
  const [article, setArticle] = useState<Article | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Function to fetch the news article by ID
    const fetchArticle = async () => {
        try {
            const response = await fetch(`${BaseUrl}/news/${id}`); // Using the 'id' parameter from the URL
            if (!response.ok) {
                throw new Error('Failed to fetch news article');
            }
            const data = await response.json();
            setArticle(data);
        } catch (error: any) { // Explicitly type 'error' as 'Error'
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    fetchArticle(); // Call the fetch function
  }, [id]); // Adding 'id' to the dependency array to trigger fetch when 'id' changes

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!article) {
    return <div>News article not found</div>;
  }

  return (
<div
  className="w-widthcon flex flex-col items-center mt-5 rounded-lg border border-wood border-opacity-50 p-5 backdrop-blur-lg"
>
      {/* <img src={article.image_url} alt={article.title} className='w-fit rounded-xl border border-white border-opacity-10'/> */}
      <h2 className='text-3xl'>{article.title}
                  <div className='w-full bg-white bg-opacity-100 h-px mt-1'></div>
</h2>
      {article.description && <p className="text-white text-md text-center mt-5" dangerouslySetInnerHTML={{ __html: article.description }} />}
                  <div className='flex flex-row mt-5'>
              {article.tags && article.tags.map(tag => (
                <span key={tag} className="inline-block border border-gray-200 rounded-lg px-3 py-1 text-sm font-semibold text-white border-opacity-20 mr-2 mb-2">{tag}</span>
            ))}
            </div>
                  <div className='w-full bg-white bg-opacity-10 h-px mt-4 mb-4'></div>
      <p>פורסם {getTimeDifference(article.date_published)}</p>
    </div>
  );
}

export default SingleNew;
