export const rankTitle = (rank: any): string => {
  switch(rank) {
    case 3: return 'מנהל השבט';
    case 2: return 'סגן מנהל השבט';
    case 1: return 'חבר בשבט';
    default: return '';
  }
};

export const rankColor = (rank: any): string => {
  switch(rank) {
    case 3: return 'text-red-500';
    case 2: return 'text-yellow-500';
    case 1: return 'text-white';
    default: return 'text-white';
  }
};