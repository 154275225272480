import { PieChart } from '@mui/x-charts/PieChart'
import axios from 'axios';
import { error } from 'console';
import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatNumber } from 'src/components/FormatNumber';
import BaseUrl from 'src/config';
const Bank = () => {
    const navigate = useNavigate();    
    const [bank, setBank] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    // Initialize inputValues after bank is set
    const [inputValues, setInputValues] = useState({
        gold: '',
        iron: '',
        wood: '',
        food: '',
        bank_gold: '',
        bank_iron: '',
        bank_wood: '',
        bank_food: '',
    });

    const fetchPlayerBank = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${BaseUrl}/stats`, {
                headers: {
                    Authorization: token,
                },
            });
            setBank(response.data);
            // Initialize inputValues after bank is set
            setInputValues({
                gold: response.data ? response.data.gold : '',
                iron: response.data ? response.data.iron : '',
                wood: response.data ? response.data.wood : '',
                food: response.data ? response.data.food : '',
                bank_gold: response.data ? response.data.bank_gold : '',
                bank_iron: response.data ? response.data.bank_iron : '',
                bank_wood: response.data ? response.data.bank_wood : '',
                bank_food: response.data ? response.data.bank_food : '',
            });
            setLoading(false);
        } catch (error) {
            if ((error as any).response && (error as any).response.status === 401) {
                localStorage.removeItem('token');
                navigate('/login')
            } else {
                setError(error as Error);
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPlayerBank(); // Initial fetch

        const interval = setInterval(() => {
            fetchPlayerBank(); // Fetch data at regular intervals
        }, 20000); // Refresh every minute (adjust as needed)

        return () => clearInterval(interval); // Cleanup the interval on component unmount
    }, []);

    if (loading) {
        return <div><Skeleton /></div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>; // Access error.message to display error message
    }
type ResourceType = 'gold' | 'iron' | 'wood' | 'food' | 'bank_gold';

const handleInputChange = (resourceType: ResourceType, newValue: any) => {
  setInputValues({
    ...inputValues,
    [resourceType]: newValue,
});

};

const handleButtonClick = (resourceType: ResourceType, action: 'deposit' | 'withdraw') => {
  const inputValue = Number(inputValues[resourceType]);

  if (action === 'deposit') {
    // Check if the user has enough of the resource
    if (bank[resourceType] >= inputValue) {
      // Send a request to deposit the resource
      axios.post(`${BaseUrl}/depositResource`, {
        userId: bank.id,
        resourceType: resourceType,
        depositAmount: inputValue,
      })
        .then(response => {
          const data = response.data;
          console.log('Response data:', data);
          fetchPlayerBank();
          if (data.message) {
            toast.success(data.message);
          }
        })
        .catch(error => {
          console.error('Error:', error);
          if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          }
        });
    } else {
      const translatedResourceType = getResourceTypeTranslation(resourceType);
      toast.error(`אין לך מספיק ${translatedResourceType}`);
    }
  } else if (action === 'withdraw') {
    // Send a request to withdraw the resource
axios.post(`${BaseUrl}/withdrawResource`, {
      userId: bank.id,
      resourceType: resourceType,
      withdrawAmount: inputValue,
    })
      .then(response => {
        const data = response.data;
        console.log('Response data:', data);
        fetchPlayerBank();
        if (data.message) {
          toast.success(data.message);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        }
      });
  }
};
const getResourceTypeTranslation = (resourceType: ResourceType): string => {
  switch (resourceType) {
    case 'iron':
      return 'ברזל';
    case 'wood':
      return 'עץ';
    case 'gold':
      return 'זהב';
    case 'food':
      return 'אוכל';
    default:
      return '';
  }
};
  return (
<div className='w-widthcon mb-5 flex flex-row flex-wrap '>
    <div className='w-full flex gap-5'>
    <div className='w-1/2 bg-black backdrop-blur-lg bg-opacity-20 mt-5 mb-5 p-5 rounded-lg border border-white border-opacity-10'>

							<i className="fa fa-download text-gold text-xl ml-1"></i>
							<span className="text-gold text-xl">הפקד זהב</span>
                            <div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                            <div className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col'>
                                {formatNumber(bank?.bank_gold)}
                                <span>זהב בבנק</span>
                            </div>
						<div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                        <div className='flex gap-2'>
                        <input 
  onChange={(e) => handleInputChange('gold', e.target.value)}
  className='w-2/3 p-2 bg-white bg-opacity-10 rounded border border-white border-opacity-10' 
  type="text"
  value={inputValues.gold}
/>
                        <button onClick={() => handleButtonClick('gold', 'deposit')}
                         className='w-1/3 border border-white border-opacity-20 rounded p-2 hover:bg-gold hover:bg-opacity-30 hover:backdrop-blur-lg transition-all duration-150 text-xl bg-gold bg-opacity-10'>הפקד</button>
                        </div>
        </div>
        <div className='w-1/2 bg-black backdrop-blur-lg bg-opacity-20 mt-5 mb-5 p-5 rounded-lg border border-white border-opacity-10'>
		<i className="fa fa-upload text-gold text-xl ml-1"></i>
							<span className="text-gold text-xl">הוצא זהב</span>
                                <div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                            <div className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col'>
                                {formatNumber(bank?.gold)}
                                <span>זהב</span>
                            </div>
						<div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                        <div className='flex gap-2'>
                        <input 
                      onChange={(e) => handleInputChange('bank_gold', e.target.value)}
                      className='w-2/3 p-2 bg-white bg-opacity-10 rounded border border-white                   border-opacity-10' 
                      type="text"
                      value={inputValues.bank_gold}
                    />
                        <button onClick={() => handleButtonClick('gold', 'withdraw')}
                         className='w-1/3 border border-white border-opacity-20 rounded p-2 hover:bg-gold hover:bg-opacity-30 hover:backdrop-blur-lg transition-all duration-150 text-xl bg-gold bg-opacity-10'>הוצא</button>
                        </div>

        </div>
        </div>
        <div className='w-full flex gap-5'>
    <div className='w-1/2 bg-black backdrop-blur-lg bg-opacity-20 mb-5 p-5 rounded-lg border border-white border-opacity-10'>

							<i className="fa fa-download text-iron text-xl ml-1"></i>
							<span className="text-iron text-xl">הפקד ברזל</span>
                            <div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                            <div className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col'>
                                {formatNumber(bank?.bank_iron)}
                                <span>ברזל בבנק</span>
                            </div>
						<div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                        <div className='flex gap-2'>
                                                <input 
  onChange={(e) => handleInputChange('iron', e.target.value)}
  className='w-2/3 p-2 bg-white bg-opacity-10 rounded border border-white border-opacity-10' 
  type="text"
  value={inputValues.iron}
/>
                        <button
                        onClick={() => handleButtonClick('iron', 'deposit')}
                        className='w-1/3 border border-white border-opacity-20 rounded p-2 hover:bg-iron hover:bg-opacity-30 hover:backdrop-blur-lg transition-all duration-150 text-xl bg-iron bg-opacity-10'>הפקד</button>
                        </div>
        </div>
        <div className='w-1/2 bg-black backdrop-blur-lg bg-opacity-20  mb-5 p-5 rounded-lg border border-white border-opacity-10'>
		<i className="fa fa-upload text-iron text-xl ml-1"></i>
							<span className="text-iron text-xl">הוצא ברזל</span>
                                <div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                            <div className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col'>
                                {formatNumber(bank?.iron)}
                                <span>ברזל</span>
                            </div>
						<div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                        <div className='flex gap-2'>
                        <input className='w-2/3 p-2 bg-white bg-opacity-10 rounded border border-white border-opacity-10' value={bank?.bank_iron} type="text" />
                        <button className='w-1/3 border border-white border-opacity-20 rounded p-2 hover:bg-iron hover:bg-opacity-30 hover:backdrop-blur-lg transition-all duration-150 text-xl bg-iron bg-opacity-10'>הוצא</button>
                        </div>

        </div>
        </div>
        <div className='w-full flex gap-5'>
    <div className='w-1/2 bg-black backdrop-blur-lg bg-opacity-20 mb-5 p-5 rounded-lg border border-white border-opacity-10'>

							<i className="fa fa-download text-wood text-xl ml-1"></i>
							<span className="text-wood text-xl">הפקד עץ</span>
                            <div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                            <div className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col'>
                                {formatNumber(bank?.bank_wood)}
                                <span>עץ בבנק</span>
                            </div>
						<div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                        <div className='flex gap-2'>
                                                <input 
  onChange={(e) => handleInputChange('wood', e.target.value)}
  className='w-2/3 p-2 bg-white bg-opacity-10 rounded border border-white border-opacity-10' 
  type="text"
  value={inputValues.wood}
/>
                        <button
                        onClick={() => handleButtonClick('wood', 'deposit')}
                        className='w-1/3 border border-white border-opacity-20 rounded p-2 hover:bg-wood hover:bg-opacity-30 hover:backdrop-blur-lg transition-all duration-150 text-xl bg-wood bg-opacity-10'>הפקד</button>
                        </div>
        </div>
        <div className='w-1/2 bg-black backdrop-blur-lg bg-opacity-20  mb-5 p-5 rounded-lg border border-white border-opacity-10'>
		<i className="fa fa-upload text-wood text-xl ml-1"></i>
							<span className="text-wood text-xl">הוצא עץ</span>
                                <div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                            <div className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col'>
                                {formatNumber(bank?.wood)}
                                <span>עץ</span>
                            </div>
						<div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                        <div className='flex gap-2'>
                        <input className='w-2/3 p-2 bg-white bg-opacity-10 rounded border border-white border-opacity-10' value={bank?.bank_wood} type="text" />
                        <button className='w-1/3 border border-white border-opacity-20 rounded p-2 hover:bg-wood hover:bg-opacity-30 hover:backdrop-blur-lg transition-all duration-150 text-xl bg-wood bg-opacity-10'>הוצא</button>
                        </div>

        </div>
        </div>
        <div className='w-full flex gap-5'>
    <div className='w-1/2 bg-black backdrop-blur-lg bg-opacity-20 mb-5 p-5 rounded-lg border border-white border-opacity-10'>

							<i className="fa fa-download text-food text-xl ml-1"></i>
							<span className="text-food text-xl">הפקד אוכל</span>
                            <div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                            <div className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col'>
                                {formatNumber(bank?.bank_food)}
                                <span>אוכל בבנק</span>
                            </div>
						<div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                        <div className='flex gap-2'>
                                                <input 
  onChange={(e) => handleInputChange('food', e.target.value)}
  className='w-2/3 p-2 bg-white bg-opacity-10 rounded border border-white border-opacity-10' 
  type="text"
  value={inputValues.food}
/>
                        <button
                        onClick={() => handleButtonClick('food', 'deposit')}
                        className='w-1/3 border border-white border-opacity-20 rounded p-2 hover:bg-food hover:bg-opacity-30 hover:backdrop-blur-lg transition-all duration-150 text-xl bg-food bg-opacity-10'>הפקד</button>
                        </div>
        </div>
        <div className='w-1/2 bg-black backdrop-blur-lg bg-opacity-20  mb-5 p-5 rounded-lg border border-white border-opacity-10'>
		<i className="fa fa-upload text-food text-xl ml-1"></i>
							<span className="text-food text-xl">הוצא אוכל</span>
                                <div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                            <div className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col'>
                                {formatNumber(bank?.food)}
                                <span>אוכל</span>
                            </div>
						<div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                        <div className='flex gap-2'>
                        <input className='w-2/3 p-2 bg-white bg-opacity-10 rounded border border-white border-opacity-10' value={bank?.bank_food} type="text" />
                        <button className='w-1/3 border border-white border-opacity-20 rounded p-2 hover:bg-food hover:bg-opacity-30 hover:backdrop-blur-lg transition-all duration-150 text-xl bg-food bg-opacity-10'>הוצא</button>
                        </div>

        </div>
        </div>
                <div className='w-full flex gap-5'>
    <div className='w-1/2 bg-black backdrop-blur-lg bg-opacity-20 mb-5 p-5 rounded-lg border border-white border-opacity-10 aria-disabled:cursor-not-allowed' aria-disabled>
        <div className='w-full flex justify-center items-center h-full -m-5 absolute text-5xl text-center text-white font-semibold bg-black z-50 rounded-lg border border-1 border-white border-opacity-50 bg-opacity-70 cursor-not-allowed'>לא זמין כעת</div>

							<i className="fa fa-calculator text-gold text-xl ml-1"></i>
							<span className="text-gold text-xl">שדרג ריבית בזהב</span>
                            <div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                            <div className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col'>
                                0
                                <span>זהב בעדכון יומי</span>
                            </div>
						<div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                            <div className="relative h-5 rounded-md overflow-hidden bg-white bg-opacity-10 mt-5 mx-15 border border-white border-opacity-10 p-3  outline-2 mb-5"><div className='absolute flex justify-center items-center top-0 bottom-0 left-0 text-white w-full z-40 text-center'>5/<span className='font-semibold'>0</span></div>
      <div className="text-white font-bold text-center absolute top-0 bottom-0 left-0 rounded-md bg-white bg-opacity-20" style={{ width: 0 }}>
        
      </div>
    </div>
						<div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                        <div className='flex gap-5'>
<div className='w-1/2 text-center'>
    <span className='text-xl'>עלות שדרוג</span>
    
    <div className='bg-gold p-2 flex flex-col mt-2 rounded-lg bg-opacity-20 backdrop-blur-lg border border-white border-opacity-10'>
    1,500,000
    <span>זהב בבנק</span>
    </div>
</div>
<div className='w-1/2 text-center'>
    <span className='text-xl'>שדרוג ל</span>
    
    <div className='bg-gold p-2 flex flex-col mt-2 rounded-lg bg-opacity-20 backdrop-blur-lg border border-white border-opacity-10'>
    1
    <span>אחוזי ריבית זהב</span>
    </div>
</div>
</div>
                        <div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                        <div className='flex gap-2'>
                        
                        <button className='w-full border border-white border-opacity-20 rounded p-2 hover:bg-gold hover:bg-opacity-30 hover:backdrop-blur-lg transition-all duration-150 text-xl bg-gold bg-opacity-10 disabled:cursor-not-allowed' disabled>שדרג</button>
                        </div>
        </div>
        <div className='w-1/2 bg-black backdrop-blur-lg bg-opacity-20 mb-5 p-5 rounded-lg border border-white border-opacity-10'>

							<i className="fa fa-calculator text-gold text-xl ml-1"></i>
							<span className="text-gold text-xl">שדרג הגבלת הפקדות</span>
						<div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                            <div className="relative h-5 rounded-md overflow-hidden bg-white bg-opacity-10 mt-5 mx-15 border border-white border-opacity-10 p-3 outline-2 mb-5"><div className='absolute flex justify-center items-center top-0 bottom-0 left-0 text-white w-full z-50 text-center'>30/<span className='font-semibold'>6</span></div>
      <div className="text-white font-bold text-center absolute top-0 bottom-0 left-0 rounded-md bg-white bg-opacity-20" style={{ width: 0 }}>
        
      </div>
    </div>
						<div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                        <div className='flex gap-5'>
<div className='w-1/2 text-center'>
    <span className='text-xl'>עלות שדרוג</span>
    
    <div className='bg-white p-2 flex flex-col mt-2 rounded-lg bg-opacity-10 backdrop-blur-lg border border-white border-opacity-10'>
    350,000
    <span>זהב בבנק</span>
    </div>
</div>
<div className='w-1/2 text-center'>
    <span className='text-xl'>שדרוג ל</span>
    
    <div className='bg-white p-2 flex flex-col mt-2 rounded-lg bg-opacity-10 backdrop-blur-lg border border-white border-opacity-10'>
    7
    <span>הפקדות</span>
    </div>
</div>
</div>
                        <div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                        <div className='flex gap-2'>
                        
                        <button className='w-full border border-white border-opacity-20 rounded p-2 hover:bg-gold hover:bg-opacity-30 hover:backdrop-blur-lg transition-all duration-150 text-xl bg-gold bg-opacity-10'>שדרג</button>
                        </div>
        </div>
        </div>
        <div className='w-full flex gap-5'>
    <div className='w-1/2 bg-black backdrop-blur-lg bg-opacity-20 mb-5 p-5 rounded-lg border border-white border-opacity-10'>

							<i className="fa fa-th-list text-xl ml-1"></i>
							<span className="text-xl">משאבים בבנק</span>
                            <div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                            <div className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col mb-2 text-gold'>
                                {formatNumber(bank?.bank_gold)}
                                <span>זהב בבנק</span>
                            </div>
                            <div className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col mb-2 text-iron'>
                                {formatNumber(bank?.bank_iron)}
                                <span>ברזל בבנק</span>
                            </div>
                            <div className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col mb-2 text-wood'>
                                {formatNumber(bank?.bank_wood)}
                                <span>עץ בבנק</span>
                            </div>
                            <div className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col mb-2 text-food'>
                                {formatNumber(bank?.bank_food)}
                                <span>אוכל בבנק</span>
                            </div>
						
<div className='flex justify-center items-center w-150 h-110'>
        <PieChart

  series={
    [
    
    {
      faded: { innerRadius: 10, additionalRadius: -10, color: 'gray' },
                  highlightScope: { faded: 'global', highlighted: 'item' },
     data: 
      [
        { id: 0, value: bank?.bank_gold, color: '#f39c12', label: 'זהב' },
        { id: 1, value: bank?.bank_iron, color: '#00bc8c', label: 'ברזל' },
        { id: 2, value: bank?.bank_wood, color: '#e63131', label: 'עץ' },
        { id: 3, value: bank?.bank_food, color: '#31dce6', label: 'אוכל' },
        
      ],
      innerRadius: 0,
      outerRadius: 80,
      paddingAngle: 0,
      cornerRadius: 0,
    }
    
  ]
  }
        margin={{ right: 5 }}
        width={200}
        height={200}
        slotProps={{
          legend: { hidden: true },
        }}

/></div>
</div>
         <div className='w-1/2 bg-black backdrop-blur-lg bg-opacity-20 mb-5 p-5 rounded-lg border border-white border-opacity-10'>

							<i className="fa fa-university text-xl ml-1"></i>
							<span className="text-xl">בנק</span>
                            <div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>
                            <div className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col mb-2'>
                                6
                                <span>הפקדות</span>
                            </div>
                            <div className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col mb-2'>
                                2
                                <span>הפקדות שבוצעו</span>
                            </div>
                            <div className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col mb-2'>
                                4
                                <span>הפקדות שנותרו</span>
                            </div>
                            <div className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col mb-2'>
                                0
                                <span>ריבית זהב בעדכון יומי</span>
                            </div>
						
<div className='flex justify-center items-center w-150 h-110'>
        <PieChart

  series={
    [
    
    {
      faded: { innerRadius: 10, additionalRadius: -10, color: 'gray' },
                  highlightScope: { faded: 'global', highlighted: 'item' },
     data: 
      [
        { id: 0, value: 1, color: '#f39c12', label: 'זהב' },
        { id: 1, value: 1, color: '#00bc8c', label: 'ברזל' },
        { id: 2, value: 1, color: '#e63131', label: 'עץ' },
        { id: 3, value: 1, color: '#31dce6', label: 'אוכל' },
        
      ],
      innerRadius: 0,
      outerRadius: 80,
      paddingAngle: 0,
      cornerRadius: 0,
    }
    
  ]
  }
        margin={{ right: 5 }}
        width={200}
        height={200}
        slotProps={{
          legend: { hidden: true },
        }}

/></div>
</div>
        </div>
    </div>
    
  )
}

export default Bank
