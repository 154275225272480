import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {jwtDecode} from 'jwt-decode';
import BaseUrl from 'src/config';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './Newletter.scss'
interface User {
  id: string;
  nickname: string;
}

const NewLetter = () => {
  const [receiverId, setReceiverId] = useState('');
  const [receiverNickname, setReceiverNickname] = useState('');
  const [messageContent, setMessageContent] = useState('');
  const [messageTitle, setMessageTitle] = useState('');
  const [userList, setUserList] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserList();
  }, []);

  const fetchUserList = async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const response = await axios.get(`${BaseUrl}/selectPlayers`, {
          headers: {
            Authorization: `${token}`, // Add Bearer to token for authorization
          },
        });

        if (response.data && Array.isArray(response.data.players)) {
          setUserList(response.data.players);
        } else {
          console.error('Expected an array but received:', response.data);
          setUserList([]); // Fallback to an empty array if the format is unexpected
        }
      }
    } catch (error) {
      console.error('Error fetching user list:', error);
      setUserList([]); // Ensure userList is an array even when an error occurs
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setReceiverNickname(input);

    if (Array.isArray(userList)) {
      const filtered = userList.filter((user) =>
        user.nickname && user.nickname.toLowerCase().includes(input.toLowerCase())
      );
      setFilteredUsers(filtered);
    } else {
      console.error('userList is not an array:', userList);
      setFilteredUsers([]);
    }

    setIsDropdownOpen(input !== ''); // Open the dropdown only if input is not empty
  };

  const handleUserSelect = (selectedUserId: string, selectedUserNickname: string) => {
    setReceiverId(selectedUserId);
    setReceiverNickname(selectedUserNickname);
    setIsDropdownOpen(false);
  };

const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();

  const token = localStorage.getItem('token');

  if (!token) {
    console.error('No token found in localStorage');
    return;
  }

  const decodedToken: any = jwtDecode(token);
  const userId = decodedToken.id;

  try {
    const response = await axios.post(
      `${BaseUrl}/messages1`,
      {
        sender_id: userId,
        receiver_nickname: receiverNickname,
        message_content: messageContent,
        message_title: messageTitle,
      },
      {
        headers: {
          Authorization: `${token}`, // Correct Authorization header
        },
      }
    );

    toast.success('המכתב נשלח בהצלחה!');
    navigate('/letters/outbox');
  } catch (error) {
    console.error('Error sending message:', error);
    toast.error('Failed to send the message. Please try again.');
  }
};


  return (
    <div className="w-widthcon">
      <div className="letters bg-black bg-opacity-30 backdrop-blur-lg rounded-lg border border-white border-opacity-10 mt-5 mb-5 flex justify-around text-center">
    <Link
        className="p-3 m-2 w-1/4 text-2xl bg-black bg-opacity-20 transition-all duration-100 rounded-md border border-white border-opacity-5"
        to="/letters/outbox"
    >
        דואר יוצא
    </Link>
    <Link
        className="p-3 m-2 w-1/4 text-2xl bg-black bg-opacity-20 transition-all duration-100 rounded-md border border-white border-opacity-5"
        to="/letters/inbox"
    >
        דואר נכנס
    </Link>
    <Link
        className="p-3 m-2 w-1/4 text-2xl bg-black bg-opacity-40 transition-all duration-100 rounded-md border border-white border-opacity-5"
        to="/letters/new_letter"
    >
        שלח מכתב חדש
    </Link>
</div>

      <form onSubmit={handleSubmit} className="bg-black bg-opacity-20 backdrop-blur-md border border-opacity-10 border-white w-full gap-5 p-5 flex flex-row rounded-xl sm:flex-col sm:justify-center mb-5">
        <div>
          <input
            placeholder="נמען"
            className="border border-black border-dashed w-full bg-black bg-opacity-20 p-3 text-white"
            type="text"
            value={receiverNickname}
            onChange={handleInputChange}
            title="הכנס את שם הנמען"
            alt="נמען"
          />
          {isDropdownOpen && (
            <ul className="dropdown mt-1 flex flex-col gap-0.5">
              {filteredUsers.map((user) => (
                <li
                  className="bg-black bg-opacity-20 cursor-pointer hover:bg-opacity-40 transition-all duration-100 p-1"
                  key={user.id}
                  onClick={() => handleUserSelect(user.id, user.nickname)}
                  title={`בחר ${user.nickname}`}
                >
                  {user.nickname}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div>
          <input
            placeholder="כותרת ההודעה"
            className="border border-black border-dashed w-full bg-black bg-opacity-20 p-3 text-white"
            type="text"
            value={messageTitle}
            onChange={(e) => setMessageTitle(e.target.value)}
            title="הכנס את כותרת ההודעה"
            alt="כותרת ההודעה"
          />
        </div>
        <div>
          <ReactQuill
            theme="snow"
            value={messageContent}
            onChange={setMessageContent}
            modules={{
              toolbar: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                ['bold', 'italic', 'underline'],
                ['link', 'image'],
                [{ 'direction': 'rtl' }], // Right to Left support
                [{ 'align': [] }],
                ['clean'] // Remove formatting
              ],
            }}
            formats={[
              'header', 'font',
              'list', 'bullet',
              'bold', 'italic', 'underline',
              'link', 'image', 'direction', 'align',
            ]}
            placeholder="תוכן ההודעה"
            className="border border-black border-1 border-opacity-20 w-full bg-black bg-opacity-20 text-white"
          />
        </div>
        <button
          className="w-full hover:bg-opacity-50 transition-all duration-100 bg-black bg-opacity-40 font-bold p-3 text-2xl"
          type="submit"
          title="שלח את המכתב"
        >
          שלח מכתב
        </button>
      </form>
    </div>
  );
};

export default NewLetter;
