import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import BaseUrl from 'src/config';

const Nickname = () => {
    const [nickname, setNickname] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // Clear previous error and success messages
        setError('');
        setSuccess('');

        // Validate nickname
        if (!nickname) {
            setError('חייב למלא את שדה הכינוי');
            return;
        }
       
        if (nickname.length > 12) {
            setError('Nickname must be up to 12 characters long');
            return;
        }

        try {
            // Send request to server to insert nickname
            const token = localStorage.getItem('token');
            
            const response = await fetch(`${BaseUrl}/insertNickname`, {
                method: 'POST',
                headers: {
                    Authorization: token ? token : '',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token, nickname }),
            });

            if (!response.ok) {
                const data = await response.json();
                setError(data.message || 'Failed to insert nickname');
            } else {
                setSuccess('הכינוי שונה בהצלחה!');
                toast.success('הכינוי שונה בהצלחה!')
                navigate('/')
            }
        } catch (error) {
            console.error('Error inserting nickname:', error);
            setError('Internal server error');
        }
    };
    const handleNicknameChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        value = value.trim().slice(0, 12);

        value = value.replace(/[^א-תa-zA-Z-1-9_ ]/g, '');
        setNickname(value);
    };

    return (
        <div className='flex flex-col items-center mt-5'>
            <h2 className='text-white text-2xl'>תבחר את הכינוי שלך</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <input
                        className='border border-black border-dashed w-full rounded-lg text-white bg-black bg-opacity-30 p-3 my-5 placeholder:text-white'
                        type="text"
                        id="nickname"
                        placeholder='כינוי'
                        value={nickname}
                        onChange={handleNicknameChange} // Use filtered change handler
                    />
                </div>
                {error && <div className="error mb-5 text-red-700 text-xl text-center">{error}</div>}
                {success && <div className="success">{success}</div>}
                <button type="submit" className='p-2 border border-white border-opacity-20 backdrop-xl bg-white bg-opacity-10 hover:bg-opacity-20 transition-all duration-150 hover:scale-95 rounded-lg w-full disabled:cursor-not-allowed' disabled={!!error} >אישור</button>
            </form>
        </div>
    );
};

export default Nickname;
