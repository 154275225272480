import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider() {
  var settings = {
    autoplay: true,
    infinite: true,
    dots: true,
    speed: 400,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true, // Enable fading effect
  };
  return (
    <div className="w-widthcon max-w-screen-xl mx-auto box-border mt-5">
      <Slider {...settings}>
        <div className="h-fit p-5 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 flex flex-col items-center justify-center">
          <h3 className="text-center text-xl p-2">!הצטרפו עוד היום אלינו לדיסקורד</h3>
          <h3 className="p-2 rounded-lg bottom-0 block text-center">דיסקורד</h3>
        </div>
        <div className="h-fit p-5 rounded-lg bg-gradient-to-r from-violet-500 to-fuchsia-500 flex flex-col items-center justify-center">
          <h3 className="text-center text-xl p-2">!הצטרפו עוד היום אלינו לדיסקורד</h3>
          <h3 className="p-2 rounded-lg bottom-0 block text-center">דיסקורד</h3>
        </div>
        <div className="h-fit p-5 rounded-lg bg-gradient-to-r from-sky-500 to-indigo-500 flex flex-col items-center justify-center">
          <h3 className="text-center text-xl p-2">!הצטרפו עוד היום אלינו לדיסקורד</h3>
          <h3 className="p-2 rounded-lg bottom-0 block text-center">דיסקורד</h3>
        </div>
        <div className="h-fit p-5 rounded-lg bg-gradient-to-r from-purple-500 to-pink-500 flex flex-col items-center justify-center">
          <h3 className="text-center text-xl p-2">!הצטרפו עוד היום אלינו לדיסקורד</h3>
          <h3 className="p-2 rounded-lg bottom-0 block text-center">דיסקורד</h3>
        </div>
      </Slider>
    </div>
  );
}
