import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from 'src/config';
import { toast } from 'react-toastify';

const VerificationComponent = () => {
  const navigate = useNavigate();
  const { code } = useParams();
  const [error, setError] = useState<string | null>(null); // Explicitly typing error as string or null

  useEffect(() => {
    const verifyCode = async () => {
      try {
        const url = `${BaseUrl}/verify/${code}`;
        const response = await axios.get(url);

        if (response.data && response.data.message === 'User already verified') {
          setError('משתמש זה כבר אושר');
          toast.warning("משתמש זה כבר אושר");
        } else {
         
          toast.success("המשתמש אומת בהצלחה, תוכל להתחבר עכשיו");
          navigate('/');
        }
      } catch (error: any) {
        if (error.response && error.response.data && error.response.data.message === 'User already verified') {
          setError('משתמש זה כבר אושר');
          toast.warning("משתמש זה כבר אושר");
          navigate('/');
        } else {
          setError('אימות המשתמש נכשלה, נסה שוב');
        }
      }
    };

    if (code) {
      verifyCode();
    }
  }, [code, navigate]);

  return (
    <div className='flex items-center justify-center h-screen'>
      <div className='rounded-lg bg-black border border-opacity-40 border-white bg-opacity-80 backdrop-blur-md p-10'>
        {error ? (
          <h2>שגיאה: {error}</h2>
        ) : (
          <h2>מאשר משתמש...</h2>
        )}
      </div>
    </div>
  );
};

export default VerificationComponent;
