import React, { useState, useEffect } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import axios from 'axios';
import BaseUrl from 'src/config';

interface Package {
  name: string;
  id: number;
  diamonds: number;
  turns: number;
  price: number;
  discount: number;
}

function Buy() {
  const [packages, setPackages] = useState<Package[]>([]);
  const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);
  const [paymentStatus, setPaymentStatus] = useState<string | null>(null);

  useEffect(() => {
    axios.get(`${BaseUrl}/packages`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    })
      .then(response => {
        setPackages(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the packages!', error);
      });
  }, []);

  const handlePackageSelect = (pkg: Package) => {
    setSelectedPackage(pkg);
  };

  const handleApprove = (data: any, actions: any) => {
    if (actions && actions.order) {
      return actions.order.capture().then((details: any) => {
        console.log('Payment approved! Details:', details);
        axios.post(`${BaseUrl}/checkout`, { packageId: selectedPackage?.id }, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
        })
          .then(response => {
            if (response.data.success) {
              setPaymentStatus('success');
              alert(`רכישה מוצלחת של חבילה ${selectedPackage?.id}`);
              console.log('Backend response:', response.data);
            } else {
              setPaymentStatus('failure');
              console.error('Backend error:', response.data.message);
            }
          }).catch((error: any) => {
            setPaymentStatus('error');
            console.error('There was an error processing the payment!', error);
          });
      }).catch((error: any) => {
        setPaymentStatus('error');
        console.error('Error during capture:', error);
        handleError(error);
      });
    }
    return Promise.reject(new Error("Actions or actions.order is undefined"));
  };

  const handleApproveTest = () => {
    const fakeData = {}; // נתונים מדומים לבדיקת הפונקציה
    const fakeActions = {
      order: {
        capture: () => Promise.resolve({
          id: 'FAKE_ORDER_ID',
          status: 'COMPLETED',
          // הוסף כאן נתונים נוספים בהתאם לצורך
        })
      }
    };
    handleApprove(fakeData, fakeActions).then(() => {
      axios.post(`${BaseUrl}/log-order`, { packageId: selectedPackage?.id, error: 'Test payment' }, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
      })
        .then(response => {
          if (response.data.success) {
            console.log('Test payment logged successfully');
          } else {
            console.error('Logging test payment failed:', response.data.message);
          }
        })
        .catch(error => {
          console.error('There was an error logging the test payment!', error);
        });
    }).catch((error: any) => {
      console.error('Test payment failed!', error);
    });
  };

  const handleError = (error: any) => {
    console.error('PayPal Buttons onError:', error);
    if (paymentStatus !== 'success') { // Only log if payment was not successful
      axios.post(`${BaseUrl}/log-order`, { packageId: selectedPackage?.id, error: error.message }, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
      }).catch((logError) => {
        console.error('Failed to log order:', logError);
      });
    }
  };


  return (
    <PayPalScriptProvider options={{ clientId: "AcPtaYZgYSzutlxGVCV_CQDmnFFdnvr9HmFsU5Iho1aOiXkZm07bGEImXe37HXyxdUT1AO553ZgFhzQt", currency: "ILS" }}>
      <div className="container mx-auto w-widthcon">
        <h1 className="text-3xl font-bold text-center mt-6 mb-6">רכישת חבילות</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {packages.map(pkg => (
            <div key={pkg.id} className="flex flex-col justify-between border border-white border-opacity-20 p-4 rounded-lg shadow-md bg-black bg-opacity-20 backdrop-blur-lg text-white text-center">
              <div>
                <h2 className="text-2xl font-bold mb-2">{pkg.name}</h2>
                <p className="mb-1">יהלומים: {pkg.diamonds}</p>
                <p className="mb-1">תורות: {pkg.turns}</p>
                {pkg.discount > 1 ? (
                  <>
                    <p className="mb-1 line-through">מחיר: ₪{pkg.price}</p>
                    <p className="mb-1">מחיר לאחר הנחה: ₪{(pkg.price * (1 - pkg.discount / 100)).toFixed(2)}</p>
                  </>
                ) : (
                  <p className="mb-1">מחיר: ₪{pkg.price}</p>
                )}
                {pkg.discount > 1 && (
                  <p className="mb-4">הנחה: {pkg.discount}%</p>
                )}
              </div>
              <button 
                onClick={() => handlePackageSelect(pkg)} 
                className="mt-4 w-full bg-white text-black py-2 rounded hover:text-white hover:bg-green-500 bg-opacity-40 transition duration-300 text-3xl active:scale-95"
              >
                קנייה
              </button>
            </div>
          ))}
        </div>
        {selectedPackage && (
          <div className="mt-6 p-4 border rounded-lg shadow-md bg-black bg-opacity-20 backdrop-blur-lg border-white border-opacity-20 text-white flex flex-col items-center">
            <h2 className="text-2xl font-bold mb-4">תשלום עבור {selectedPackage.name}</h2>
            <div className='w-1/2'>
              <PayPalButtons
                createOrder={(data, actions) => {
                  if (actions && actions.order) {
                    return actions.order.create({
                      intent: 'CAPTURE',
                      purchase_units: [{
                        amount: {
                          currency_code: "ILS",
                          value: (selectedPackage.price * (1 - selectedPackage.discount / 100)).toFixed(2),
                        },
                      }],
                    });
                  }
                  return Promise.reject(new Error("Actions or actions.order is undefined"));
                }}
                onApprove={handleApprove}
                onError={handleError}
              />
            </div>
            <button 
              onClick={handleApproveTest} 
              className="mt-4 w-1/2 bg-green-500 text-white py-2 rounded hover:bg-green-600 transition duration-300"
            >
              בדוק תשלום
            </button>
          </div>
        )}
        <div className="bg-red-500 rounded-lg mt-5 border border-red-700 p-4 w-full ">
          חבילות אלה אינן ניתנות להחזרה כספית.
          <br />
ברכישת חבילה, הלקוח מאשר כי הוא מבין ומקבל כי לא יתבצעו החזרות כספיות עבור החבילה או החבילות שרכש, כולל במקרים של באן על ידי בעלי האתר.
        </div>
      </div>
    </PayPalScriptProvider>
  );
}

export default Buy;
