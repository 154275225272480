import React from 'react'
import spacer from "../images/spacer.png"
const Footer = () => {

  return (
    <div className='w-widthcon rounded bg-[#747474] bg-opacity-30 backdrop-blur-lg grid grid-cols-3  justify-items-center p-5 relative border border-1 border-white border-opacity-30 text-white mb-8 mt-5 max-lg:grid-cols-2 max-md:gap-5 '>
        <img className='absolute opacity-70 -top-spacerh' src={spacer} alt="" />
        <div>
            
            <p className='text-xl mb-2'>קישורים</p>
            <ul className='flex flex-col gap-1'>
                <li>קישור</li>
                <li>קישור</li>
                <li>קישור</li>
                <li>קישור</li>
                <li>קישור</li>
                <li>קישור</li>
            </ul>
        </div>
        
        <div>
            <p className='text-xl mb-2'>קישורים</p>
            <ul className='flex flex-col gap-1'>
                <li>קישור</li>
                <li>קישור</li>
                <li>קישור</li>
                <li>קישור</li>
                <li>קישור</li>
                <li>קישור</li>
            </ul>
        </div>
        <div className='flex flex-col w-full max-lg:col-span-2'>
            <p className='text-xl mb-2'>צור קשר</p>
            <div className='flex flex-col gap-3'>
                <input className='bg-black backdrop-blur-xl bg-opacity-30 border border-white p-2 rounded text-black pl-10 flex-grow placeholder:text-white' placeholder='נושא' type="text" />
                <textarea className='bg-black backdrop-blur-xl bg-opacity-30 border border-white p-2 rounded text-black pl-10 flex-grow placeholder:text-white' placeholder='תוכן' />
                <button className='border border-1 bg-white bg-opacity-20 !text-white hover:bg-white hover:!text-black border-white transition-all px-4 py-1 rounded text-center font-semibold'>שלח</button>
            </div>
        </div>
    </div>
  )
}

export default Footer