import React from 'react'

const Loto = () => {
  return (
    <div>Loto</div>
  )
}

export default Loto


// import React, { useState } from "react";
// import { Engine, Scene } from "react-babylonjs";
// import { Vector3, SceneLoader, Scene as BabylonScene, Animation, ActionManager, ExecuteCodeAction } from "@babylonjs/core";
// import "@babylonjs/loaders"; // Ensure that Babylon.js loaders are available

// const Loto = () => {
//   const modelUrl = "/models/untitled.babylon"; // Path to the model in the public folder
//   const [isHovered, setIsHovered] = useState(false); // State to track hover state

//   // Define an empty loading screen to disable the default one
//   const emptyLoadingScreen = {
//     displayLoadingUI: () => {
//       // Do nothing
//     },
//     hideLoadingUI: () => {
//       // Do nothing
//     },
//     loadingUIBackgroundColor: "", // Can be an empty string since we're not using it
//     loadingUIText: "" // Can be an empty string since we're not using it
//   };

//   const onSceneMount = (sceneEventArgs: { scene: BabylonScene }) => {
//     const { scene } = sceneEventArgs;

//     // Set an empty loading screen to disable the default one
//     scene.getEngine().loadingScreen = emptyLoadingScreen;

//     // Load the .babylon model
//     SceneLoader.Append("", modelUrl, scene, () => {
//       console.log("Model loaded successfully!");

//       // Variables to store min and max points
//       let minVector = new Vector3(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE);
//       let maxVector = new Vector3(-Number.MAX_VALUE, -Number.MAX_VALUE, -Number.MAX_VALUE);

//       // Iterate through all meshes in the scene
//       scene.meshes.forEach((mesh) => {
//         // Make sure the mesh is pickable (interactive)
//         mesh.isPickable = true;

//         // Get the bounding box of the current mesh
//         const boundingInfo = mesh.getBoundingInfo();
//         const boundingBox = boundingInfo.boundingBox;

//         // Update min and max vectors
//         minVector = Vector3.Minimize(minVector, boundingBox.minimumWorld);
//         maxVector = Vector3.Maximize(maxVector, boundingBox.maximumWorld);

//         // Initialize actionManager if it's null
//         if (!mesh.actionManager) {
//           mesh.actionManager = new ActionManager(scene);
//         }

//         // Define static hover position
//         const targetPosition = new Vector3(2, 2, 0); // Adjust the target position

//         // OnPointerOverTrigger (when hovering)
//         mesh.actionManager.registerAction(
//           new ExecuteCodeAction(
//             ActionManager.OnPointerOverTrigger,
//             () => {
//               setIsHovered(true); // Set hover state
//               animateMeshPosition(mesh, mesh.position, targetPosition, scene);
//             }
//           )
//         );

//         // OnPointerOutTrigger (when mouse leaves)
//         mesh.actionManager.registerAction(
//           new ExecuteCodeAction(
//             ActionManager.OnPointerOutTrigger,
//             () => {
//               setIsHovered(false); // Reset hover state
//               animateMeshPosition(mesh, mesh.position, new Vector3(0, 0, 0), scene); // Reset to original position
//             }
//           )
//         );
//       });

//       // Calculate the center and size of the bounding box
//       const center = minVector.add(maxVector).scale(0.5);
//       const size = maxVector.subtract(minVector);

//       // Center all meshes by moving them relative to the center
//       scene.meshes.forEach((mesh) => {
//         mesh.position = mesh.position.subtract(center);
//       });

//       // Optionally log the size for debugging purposes
//       console.log(`Model size: ${size.toString()}`);

//       // Hide the loading screen
//       scene.getEngine().hideLoadingUI();
//     });
//   };

//   const animateMeshPosition = (mesh: any, startPosition: Vector3, endPosition: Vector3, scene: BabylonScene) => {
//     // Create an animation for the position
//     const animation = new Animation(
//       "moveMesh",
//       "position",
//       60, // Frame rate
//       Animation.ANIMATIONTYPE_VECTOR3,
//       Animation.ANIMATIONLOOPMODE_CONSTANT
//     );

//     // Define key frames
//     const keys = [
//       {
//         frame: 0,
//         value: startPosition, // Starting position
//       },
//       {
//         frame: 30, // At frame 30 (0.5 seconds if frame rate is 60fps)
//         value: endPosition, // Ending position
//       },
//     ];

//     // Add key frames to the animation
//     animation.setKeys(keys);

//     // Begin the animation
//     mesh.animations = [];
//     mesh.animations.push(animation);
//     scene.beginAnimation(mesh, 0, 30, false); // Play the animation from frame 0 to 30
//   };

//   return (
//     <div style={{ width: "100%", height: "100vh" }}>
//       <Engine antialias adaptToDeviceRatio canvasId="babylon-canvas">
//         <Scene onSceneMount={onSceneMount}>
//           {/* Adding a camera */}
//           <arcRotateCamera
//             name="camera1"
//             target={new Vector3(0, 1, 0)}
//             alpha={Math.PI / 2}
//             beta={Math.PI / 4}
//             radius={4}
//             minZ={0.001}
//             wheelPrecision={50}
//             lowerRadiusLimit={2}
//             upperRadiusLimit={10}
//             upperBetaLimit={Math.PI / 2}
//           />

//           {/* Adding some lights */}
//           <hemisphericLight
//             name="light1"
//             intensity={0.7}
//             direction={Vector3.Up()}
//           />
//           <directionalLight
//             name="dl"
//             direction={new Vector3(-1, -2, -1)}
//             position={new Vector3(0, 5, 5)}
//             intensity={0.5}
//           />
//         </Scene>
//       </Engine>
//     </div>
//   );
// };

// export default Loto;
