import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import BaseUrl from 'src/config';
import { Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { getTimeDifference } from 'src/components/getTimeDiffrence';
import { rankColor, rankTitle } from 'src/components/getRankTitle';

interface ClanMember {
  clan: string;
  rank_total: number;
  nickname: string;
  symbol: string;
  rank: any;
  paid: number;
  id: number;
}

interface ChatMessage {
  id: number;
  clan: string;
  rank: string;
  from_user: string;
  from_user_id: number;
  context: string;
  time: string;
  image?: string;
  replyTo?: number | null;
  replyToUser?: string | null;
  replyToMessage?: string | null;
}

interface JwtPayload {
  id: string;
  nickname: string;
}

const Clan: React.FC = () => {
  const [clanMembers, setClanMembers] = useState<ClanMember[]>([]);
  const [clanName, setClanName] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<'members' | 'chat'>('chat');
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [newMessage, setNewMessage] = useState<string>('');
  const [canSendMessage, setCanSendMessage] = useState<boolean>(true);
  const [images, setImages] = useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [replyTo, setReplyTo] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [typingUser, setTypingUser] = useState<string | null>(null);
  const [dropdownVisible, setDropdownVisible] = useState<number | null>(null);
  const [userId, setUserId] = useState<number | null>(null);
const [userRank, setUserRank] = useState<number | null>(null);

  const token = localStorage.getItem('token');
  const username = token ? jwtDecode<JwtPayload>(token).nickname : '';

const fetchData = async () => {
  try {
    const token = localStorage.getItem('token');
    let userRank = null;

    // Define and decode token only if it exists
    let decoded: JwtPayload | null = null;
    if (token) {
      decoded = jwtDecode<JwtPayload>(token);
    }

    const response = await axios.get(`${BaseUrl}/clan/members`, {
      headers: { Authorization: `${token}` },
    });

    setClanMembers(response.data);
    console.log(response.data);

    // Ensure decoded and id are defined before using them
    if (decoded) {
const userData = response.data.find((member: ClanMember) => member.id === parseInt(decoded?.id || '', 10));
      if (userData) {
        userRank = userData.rank;
        setUserRank(userRank);
      }
    }

    setClanName(response.data[0]?.clan || '');
  } catch (error) {
    console.error('Error fetching clan members:', error);
  }
};

  const fetchChatMessages = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/clan/chat`, {
        headers: { Authorization: `${token}` },
        params: { clan: clanName },
      });
      setMessages(response.data.reverse());
    } catch (error) {
      console.error('Error fetching chat messages:', error);
    }
  };

  const handleReply = (messageId: React.SetStateAction<number | null>) => {
    setReplyTo(messageId);
  };

  const handleDelete = async (messageId: number) => {
    if (confirm("Are you sure you want to delete this message?")) {
      try {
        await axios.delete(`${BaseUrl}/clan/chat/${messageId}`, {
          headers: { Authorization: `${token}` },
        });
        fetchChatMessages();
      } catch (error) {
        console.error('Error deleting message:', error);
      }
    }
  };

  const socket = useRef<WebSocket | null>(null);

  useEffect(() => {
    fetchData();
    socket.current = new WebSocket('ws://localhost:3002');

    socket.current.onopen = () => console.log('WebSocket connected');
    socket.current.onmessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);
      if (data.type === 'newMessage') {
        fetchChatMessages();
      } else if (data.type === 'typing') {
        setTypingUser(data.from_user);
        setTimeout(() => {
          setTypingUser(null);
          fetchChatMessages();
        }, 5000);
      }
    };

    return () => socket.current?.close();
  }, [clanName]);

  const sendMessage = async () => {
    if (!canSendMessage || (!newMessage && images.length === 0)) return;

    const formData = new FormData();
    formData.append('message', newMessage || '');
    formData.append('clan', clanName || '');
    if (replyTo) formData.append('replyTo', replyTo.toString());
    images.forEach((image) => formData.append('images', image));

    try {
      await axios.post(`${BaseUrl}/clan/chat`, formData, {
        headers: { Authorization: `${token}`, 'Content-Type': 'multipart/form-data' },
      });
      setNewMessage('');
      setImages([]);
      setImagePreviews([]);
      setReplyTo(null);
      fetchChatMessages();
      setCanSendMessage(false);
      setTimeout(() => setCanSendMessage(true), 5000);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    if (selectedFiles.length + images.length <= 3) {
      setImages((prev) => [...prev, ...selectedFiles]);
      setImagePreviews((prev) => [
        ...prev,
        ...selectedFiles.map((file) => URL.createObjectURL(file)),
      ]);
    } else {
      console.error('You can only upload up to 3 images.');
    }
  };

  const handleRemoveImage = (index: number) => {
    setImages((prev) => prev.filter((_, i) => i !== index));
    setImagePreviews((prev) => prev.filter((_, i) => i !== index));
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(event.dataTransfer.files || []);
    if (droppedFiles.length + images.length <= 3) {
      setImages((prev) => [...prev, ...droppedFiles]);
      setImagePreviews((prev) => [
        ...prev,
        ...droppedFiles.map((file) => URL.createObjectURL(file)),
      ]);
    } else {
      console.error('You can only upload up to 3 images.');
    }
  };

  const handleTyping = () => {
    if (socket.current && socket.current.readyState === WebSocket.OPEN && username) {
      socket.current.send(JSON.stringify({ type: 'typing', from_user: username }));
    }
  };

  const openImageViewer = (src: string) => {
    setSelectedImage(src);
    setIsImageViewerOpen(true);
  };

  const closeImageViewer = () => {
    setSelectedImage(null);
    setIsImageViewerOpen(false);
  };

  const renderReplyMessage = (messageId: number) => {
    const replyMessage = messages.find((msg) => msg.id === messageId);
    if (!replyMessage) return null;

    let imagePaths: string[] = [];
    if (replyMessage.image) {
      try {
        imagePaths = JSON.parse(replyMessage.image);
      } catch (error) {
        console.error('Failed to parse image paths:', error);
      }
    }

    return (
      <div className="p-2 rounded bg-gray-700 shadow-md text-white flex flex-col">
        <strong className={`text-nickname font-normal ${rankColor(replyMessage.rank)}`}>
          <Link to={`/profile/${replyMessage.from_user_id}`} className="hover:underline">
            {replyMessage.from_user}
          </Link>
        </strong>
        {formatMessage(replyMessage.context)}
        {imagePaths.length > 0 && (
          <div className="flex gap-2 mt-2 flex-col bg-gray-600 rounded-lg p-2">
            <span>תמונה/ תמונות מצורפות:</span>
            <div className='flex flex-row gap-2'>
              {imagePaths.map((src, index) => (
                <div key={index} className="cursor-pointer" onClick={() => openImageViewer(src)}>
                  <img src={`${src}`} alt={`attachment ${index}`} className="w-10 h-10 object-cover rounded" />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const formatMessage = (message: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return message.split(' ').map((part, index) =>
      urlRegex.test(part) ? (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
          {part}
        </a>
      ) : (
        part + ' '
      )
    );
  };

  return (
    <div className="w-widthcon mx-auto flex flex-col my-5">
      <div className="tabs flex justify-center mt-4">
        <button onClick={() => setActiveTab('members')} className={`p-2 ${activeTab === 'members' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}>
          Members
        </button>
              <img src="https://drive.google.com/thumbnail?id=1BEW9tkgVKlp_ebUc17LkXDH-mnPc4ome" className='w-40 h-40' alt="" />

              

        <button onClick={() => { setActiveTab('chat'); fetchChatMessages(); }} className={`p-2 ${activeTab === 'chat' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}>
          Chat
        </button>
      </div>

      {activeTab === 'chat' && (
        <div className="chat mt-5">
          {replyTo && (
            <div className="mb-2 p-2 rounded bg-gray-800 shadow-md text-white">
              <span>הגב ל {messages.find(msg => msg.id === replyTo)?.from_user || ''}</span>
              <div className="mt-2 text-gray-600">
                {renderReplyMessage(replyTo)}
              </div>
              <button onClick={() => setReplyTo(null)} className="text-red-500 ml-2">
                &times;
              </button>
            </div>
          )}

          <div className="chat-messages p-4 h-96 bg-gray-700 shadow-md max-h-[400px] overflow-y-auto
            [&::-webkit-scrollbar]:w-2
            [&::-webkit-scrollbar-track]:rounded-full
            [&::-webkit-scrollbar-track]:bg-gray-100
            [&::-webkit-scrollbar-thumb]:rounded-full
            [&::-webkit-scrollbar-thumb]:bg-gray-300
            dark:[&::-webkit-scrollbar-track]:bg-neutral-700
            dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500" 
            style={{ direction: 'rtl' }}
          >
            {typingUser && <div className="text-sm font-bold italic mb-2 text-white">{`${typingUser} מקליד...`}</div>}
            {messages.map((msg) => (
<div 
  key={msg.id} 
  className="mb-2 p-2 rounded bg-gray-800 shadow-md text-white text-right flex flex-col relative" 
  onContextMenu={(e) => {
    e.preventDefault(); // Prevent default right-click menu
    setDropdownVisible((prev) => (prev === msg.id ? null : msg.id));
  }}
>
  <div className="grid grid-cols-[1fr_auto_auto] items-center gap-2">
    <strong className={`text-nickname ${rankColor(msg.rank)}`}>
      <Link to={`/profile/${msg.from_user_id}`} className="hover:underline">
        {msg.from_user}
      </Link>
      <span className="mr-1 font-normal">({rankTitle(msg.rank)})</span>
    </strong>
    <span className="text-sm">{getTimeDifference(msg.time)}</span>
    
    <button 
      onClick={() => setDropdownVisible((prev) => (prev === msg.id ? null : msg.id))}
      className="p-2"
      aria-expanded="true"
    >
      <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 4 15">
        <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
      </svg>
    </button>

    {dropdownVisible === msg.id && (
      <div id="dropdownDots" className="z-10 bg-white divide-y rounded-lg shadow dark:bg-gray-700 w-40 absolute top-12 left-1">
        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
          <li className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600">
            <a href="#" onClick={() => handleReply(msg.id)}>
              הגב
            </a>
          </li>
          {((msg.from_user_id === userId && userRank === 1) || ['2', '3'].includes(String(userRank))) && (
            <li className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600">
              <a href="#" onClick={() => handleDelete(msg.id)}>
                מחק הודעה
              </a>
            </li>
          )}
        </ul>
      </div>
    )}
  </div>

  <div>{formatMessage(msg.context)}</div>

  {msg.replyTo && (
    <div className="mt-2">
      {renderReplyMessage(msg.replyTo)}
    </div>
  )}

  {msg.image && JSON.parse(msg.image).length > 0 && (
    <div className="flex gap-2 mt-2 flex-col bg-black bg-opacity-10 rounded-lg p-2">
      <span>{JSON.parse(msg.image).length === 1 ? "תמונה מצורפת" : "תמונות מצורפות"}</span>
      <div className="flex flex-row gap-2">
        {JSON.parse(msg.image).map((src: string, index: number) => (
          <div key={index} className="cursor-pointer" onClick={() => openImageViewer(src)}>
            <img src={`${src}`} alt={`attachment ${index}`} className="w-10 h-10 object-cover rounded" />
          </div>
        ))}
      </div>
    </div>
  )}
</div>

            ))}
          </div>


          <form onSubmit={(e) => { e.preventDefault(); sendMessage(); }}>
            <div className={`grid grid-cols-[auto,1fr,auto] items-center py-2 px-3 bg-gray-50 border-t border-t-gray-500 dark:bg-gray-700 ${isDragging ? 'border-2 border-dashed border-blue-500' : ''}`} 
                 onDrop={handleDrop} 
                 onDragOver={handleDragOver} 
                 onDragLeave={handleDragLeave}
            >
              <button type="button" onClick={() => document.getElementById('file-input')?.click()}
                      className="p-2 text-gray-500 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd"></path>
                </svg>
                <input id="file-input" type="file" accept="image/*" multiple onChange={handleImageUpload} className="hidden" />
              </button>
              <textarea
                id="chat"
                rows={1}
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyPress={handleTyping}
                className="block mx-4 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="ההודעה שלך..."
                style={{ direction: 'rtl' }}
              />
              <button type="submit" className="inline-flex justify-center p-2 text-nickname rounded-full cursor-pointer hover:bg-blue-100 dark:text-blue-500 dark:hover:bg-gray-600 mr-6">
                <svg className="w-6 h-6 rotate-90" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a 1 1 0 112 0v4.571a 1 1 0 00.725.962l5 1.428a 1 1 0 001.17-1.408l-7-14z"></path></svg>
              </button>
                        {imagePreviews.length > 0 && (
            <div className="col-span-3 flex gap-2 mt-2">
              {imagePreviews.map((preview, index) => (
                <div key={index} className="relative">
                  <button onClick={() => handleRemoveImage(index)} className="absolute leading-3 top-0 right-0 bg-red-500 hover:bg-red-600 text-white p-1">
                    &times;
                  </button>
                  <img src={preview} alt={`preview ${index}`} className="w-10 h-10 object-cover rounded" />
                </div>
              ))}
            </div>
          )}

            </div>
            
          </form>
          
        </div>
      )}

      {isImageViewerOpen && selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <button onClick={closeImageViewer} className="absolute top-0 right-4 text-red-500 text-6xl animate-pulse">
            &times;
          </button>
          <img src={`${selectedImage}`} alt="Fullscreen View" className="max-w-full max-h-full rounded shadow-lg" />
        </div>
      )}
    </div>
  );
};

export default Clan;
