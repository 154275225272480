import React from 'react'

const Prizes = () => {
  return (
    <>
    <div className='w-widthcon text-right bg-black bg-opacity-20 backdrop-blur-md border border-opacity-10 border-white p-5 flex flex-row rounded-xl sm:flex-col sm:justify-center my-5'>
        <div className='flex flex-row text-2xl items-center mr-2'>
            <i className="fa fa-user"></i>
            פרסים לדירוג כללי
          </div>       
    <table className='w-full my-5'>
        
        <thead className='bg-food rounded-t-md'>
			<tr>
				<th className='p-3'>דירוג</th>
				<th className='p-3'>יהלומים</th>
				<th className='p-3'>תורות</th>
			</tr>
		</thead>
        <thead>
            <tr className='bg-black bg-opacity-20 backdrop-blur-md'>
                <td className='p-1 px-2'>דירוג <span className='text-nickname font-semibold'>1</span></td>
                <td className='p-1 py-2 text-food'>20,000</td>
                <td className='p-1 py-2'>1,000</td>
            </tr>
            <tr className='bg-black bg-opacity-40 backdrop-blur-md'>
                <td className='p-1 px-2'>דירוג <span className='text-nickname font-semibold'>2</span></td>
                <td className='p-1 py-2 text-food'>10,000</td>
                <td className='p-1 py-2'>500</td>
            </tr>
            <tr className='bg-black bg-opacity-20 backdrop-blur-md'>
                <td className='p-1 px-2'>דירוג <span className='text-nickname font-semibold'>3</span></td>
                <td className='p-1 py-2 text-food'>5,000</td>
                <td className='p-1 py-2'>250</td>
            </tr>
            <tr className='bg-black bg-opacity-40 backdrop-blur-md'>
                <td className='p-1 px-2'>דירוג <span className='text-nickname font-semibold'>4</span></td>
                <td className='p-1 py-2 text-food'>2,000</td>
                <td className='p-1 py-2'>0</td>
            </tr>
            <tr className='bg-black bg-opacity-20 backdrop-blur-md'>
                <td className='p-1 px-2'>דירוג <span className='text-nickname font-semibold'>5</span></td>
                <td className='p-1 py-2 text-food'>1,000</td>
                <td className='p-1 py-2'>0</td>
            </tr>
            <tr className='bg-black bg-opacity-40 backdrop-blur-md'>
                <td className='p-1 px-2'>דירוג <span className='text-nickname font-semibold'>6</span></td>
                <td className='p-1 py-2 text-food'>800</td>
                <td className='p-1 py-2'>0</td>
            </tr>
            <tr className='bg-black bg-opacity-20 backdrop-blur-md'>
                <td className='p-1 px-2'>דירוג <span className='text-nickname font-semibold'>7</span></td>
                <td className='p-1 py-2 text-food'>700</td>
                <td className='p-1 py-2'>0</td>
            </tr>
            <tr className='bg-black bg-opacity-40 backdrop-blur-md'>
                <td className='p-1 px-2'>דירוג <span className='text-nickname font-semibold'>8</span></td>
                <td className='p-1 py-2 text-food'>600</td>
                <td className='p-1 py-2'>0</td>
            </tr>
            <tr className='bg-black bg-opacity-20 backdrop-blur-md'>
                <td className='p-1 px-2'>דירוג <span className='text-nickname font-semibold'>9</span></td>
                <td className='p-1 py-2 text-food'>550</td>
                <td className='p-1 py-2'>0</td>
            </tr>
            <tr className='bg-black bg-opacity-40 backdrop-blur-md'>
                <td className='p-1 px-2'>דירוג <span className='text-nickname font-semibold'>10</span></td>
                <td className='p-1 py-2 text-food'>500</td>
                <td className='p-1 py-2'>0</td>
            </tr>

        </thead>
    </table>
    </div>
    </>
  )
}

export default Prizes